//LOGIN
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const LOGIN_USER = 'login_user';
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';

//REGISTERING
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const REGISTER_USER = 'REGISTER_USER';
export const RESET_REGISTER_ERROR = 'RESET_REGISTER_ERROR';

export const REGISTER_TEST_USER_SUCCESS = 'REGISTER_TEST_USER_SUCCESS';
export const REGISTER_TEST_USER_FAIL = 'REGISTER_TEST_USER_FAIL';
export const REGISTER_TEST_USER = 'REGISTER_TEST_USER';

//RESET PASSWORD
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_RESET_PASSWORD_FORM = 'RESET_RESET_PASSWORD_FORM';

//GAMES
export const RECORD_USER_GAME = 'RECORD_USER_GAME';
export const RECORD_USER_GAME_FAIL = 'RECORD_USER_GAME_FAIL';
export const RECORD_USER_GAME_SUCCESS = 'RECORD_USER_GAME_SUCCESS';

export const DECREMENT_USER_LEVEL = 'DECREMENT_USER_LEVEL';
export const DECREMENT_USER_LEVEL_FAIL = 'DECREMENT_USER_LEVEL_FAIL';
export const DECREMENT_USER_LEVEL_SUCCESS = 'DECREMENT_USER_LEVEL_SUCCESS';

//ADMIN
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_SINGLE_USER = 'FETCH_SINGLE_USER';
export const FETCH_SINGLE_USER_SUCCESS = 'FETCH_SINGLE_USER_SUCCESS';

export const FETCH_SINGLE_USER_STATS = 'FETCH_SINGLE_USER_STATS';
export const FETCH_SINGLE_USER_STATS_SUCCESS = 'FETCH_SINGLE_USER_STATS_SUCCESS';

//PAYMENT
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const OPEN_CHECKOUT = 'OPEN_CHECKOUT';
export const END_OPEN_CHECKOUT = 'END_OPEN_CHECKOUT';
