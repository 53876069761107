import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
            '@global': {
                ul: {
                    margin: 0,
                    padding: 0,
                    listStyle: 'none',
                },
            },
            appBar: {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            toolbar: {
                flexWrap: 'wrap',
            },
            toolbarTitle: {
                flexGrow: 1,
            },
            link: {
                margin: theme.spacing(1, 1.5),
            },
            heroContent: {
                padding: theme.spacing(8, 0, 6),
            },
            cardHeader: {
                backgroundColor:
                    theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
            },
            cardPricing: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline',
                marginBottom: theme.spacing(2),
            },
            footer: {
                borderTop: `1px solid ${theme.palette.divider}`,
                marginTop: theme.spacing(8),
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
                [theme.breakpoints.up('sm')]: {
                    paddingTop: theme.spacing(6),
                    paddingBottom: theme.spacing(6),
                },
            },
            wrapper: {
                marginTop: theme.spacing(3),
                position: 'relative',
                width: '100%'
            },
            buttonProgress: {
                position: 'absolute',
                left: '50%',
                marginLeft: -12,
                marginTop: 6,
            },
            loadingText: {
                color: 'grey',
                fontSize: 13,
                textAlign: 'center',
            },

        }
    ))
;

const tiers = [

    {
        id: 1,
        title: 'ADN',
        subheader: 'Formule mensuelle',
        price: '9.99',
        description: [
            'Grilles illimitées',
            '10 niveaux à gravir',
            '20 palliers de difficulté',
        ],
        buttonText: 'Je m\'abonne',
        buttonVariant: 'contained',
    },
    {
        id: 2,
        title: 'ADN Premium',
        subheader: 'Formule annuelle',
        price: '3.99',
        fullPrice: '47.00',
        description: [
            'Grilles illimitées',
            '10 niveaux à gravir',
            '20 palliers de difficulté',
        ],
        buttonText: 'Je commence',
        buttonVariant: 'contained',
    },
];


export default function Pricing(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline/>
            {/* Hero unit */}
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Tarifs
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="p">
                    Pour profiter d'ADN de manière illimitée, nous vous proposons la formule Premium.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="center" justify="center">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} sm={8} md={5}>
                            <Box mb={5}>
                                <Card>
                                    <CardHeader
                                        title={tier.title}
                                        subheader={tier.subheader}
                                        titleTypographyProps={{align: 'center'}}
                                        subheaderTypographyProps={{align: 'center'}}
                                        action={tier.title === 'Pro' ? <StarIcon/> : null}
                                        className={classes.cardHeader}
                                    />
                                    <CardContent>
                                        <div>
                                            {
                                                tier.id === 2 &&
                                                <div style={{textAlign: 'center'}}>
                                                    <Typography component="h5" variant="h5" color="textSecondary">
                                                        {tier.fullPrice} € <span
                                                        style={{fontSize: 13}}>maintenant</span>
                                                    </Typography>
                                                    <Typography style={{marginBottom: '1rem'}} component="h6"
                                                                variant="h6" color="textSecondary">
                                                        soit
                                                    </Typography>
                                                </div>

                                            }
                                            <div className={classes.cardPricing}>
                                                <Typography component="h2" variant="h3" color="textPrimary">
                                                    {tier.price}€
                                                </Typography>
                                                <Typography variant="h6" color="textSecondary">
                                                    &nbsp;/mois
                                                </Typography>
                                            </div>

                                        </div>
                                        <ul>
                                            {tier.description.map((line) => (
                                                <Typography component="li" variant="subtitle1" align="center"
                                                            key={line}>
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </CardContent>
                                    <CardActions>
                                        <div className={classes.wrapper}>
                                            {
                                                props.checkoutLoading &&
                                                <p className={classes.loadingText}>
                                                    Redirection vers le paiement sécurisé ...
                                                </p>
                                            }

                                            <Button
                                                fullWidth
                                                variant={tier.buttonVariant}
                                                color="primary"
                                                onClick={() => props.subscribe(tier.id)}
                                                disabled={props.checkoutLoading}
                                            >
                                                {tier.buttonText}
                                            </Button>


                                            {props.checkoutLoading &&
                                                <CircularProgress size={24} className={classes.buttonProgress}/>}
                                        </div>

                                    </CardActions>
                                </Card>
                            </Box>

                        </Grid>
                    ))}
                </Grid>
            </Container>

        </React.Fragment>
    );
}
