import React from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';
import Backdrop from './Backdrop';
import TweenOneAnimation from "./TweenOneAnimation/";
import levelupSound from '../assets/levelup.mp3';

class LevelUpAnimation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
            soundStatus: Sound.status.PLAYING
        }
    }

    handleClose = () => {
        this.setState({open: false})
    };

    handleSongFinishedPlaying = () => {
        this.setState(
            {soundStatus: Sound.status.STOPPED}
        )
    };

    render() {
        return (
            <React.Fragment>
                <Sound
                    url={levelupSound}
                    volume={5}
                    playStatus={this.state.soundStatus}
                    onFinishedPlaying={this.handleSongFinishedPlaying}
                />
                <Backdrop
                    noLoader={true}
                    open={this.state.open}
                    content={<TweenOneAnimation handleClose={this.handleClose}/>}
                />
            </React.Fragment>

        );
    }
}

LevelUpAnimation.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    paused: PropTypes.bool,
};

export default LevelUpAnimation;
