import React from 'react';
import Container from "@material-ui/core/Container";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "../components/Layout";
import UserChart from "../components/UserChart";
import {fetchSingleUserData, fetchUserGameStats} from "../actions/AdminActions";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AdminUserCard from "../components/AdminUserCard";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

class UserDetails extends React.Component {

    componentDidMount() {
        const {userId} = this.props.match.params;
        this.props.fetchSingleUserData(userId);
        this.props.fetchUserGameStats(userId);
    }

    renderChart() {
        if (this.props.singleUserDetails.numberOfGames === 0) {
            return (
                <p>Aucune partie jouées. Les statistiques ne sont pas disponibles.</p>
            )
        }

        return (
            <UserChart data={this.props.stats}/>
        )
    }

    render() {
        console.log('this.props UserDetails', this.props)
        return (
            <Layout isAdmin={true}>
                <div style={{background: '#eeeeee', flex: 1}}>
                    <Container maxWidth="lg">
                        <Box mt={2}>
                            <Link to="/admin" style={{textDecoration: 'none'}}>
                                <Button variant="contained" color="primary">
                                    Retour à la liste
                                </Button>
                            </Link>
                        </Box>
                        <h1>Détails utilisateur</h1>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <h3>Infos utilisateur</h3>
                                {
                                    this.props.singleUserDetailsFetching ?
                                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                            <CircularProgress/>
                                            <p>Chargement des données</p>
                                        </div>
                                        :
                                        <AdminUserCard user={this.props.singleUserDetails}/>
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h3>Statistiques</h3>
                                {
                                    this.props.statsLoading ?
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            <CircularProgress/>
                                            <p>Chargement des statistiques</p>
                                        </div>
                                        : this.renderChart()
                                }
                            </Grid>
                        </Grid>

                    </Container>
                </div>
            </Layout>
        );
    }

}


const mapStateToProps = ({admin}) => {
    const {singleUserDetails, singleUserDetailsFetching, stats, statsLoading} = admin;
    return {singleUserDetails, singleUserDetailsFetching, stats, statsLoading};
};

const mapDispatchToProps = {
    fetchSingleUserData,
    fetchUserGameStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
