import React from 'react';
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        height: '100vh',
    },
    link: {
        textDecoration: 'none',
    }
}));

function NoMatchPage() {

    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.container}>
            <h1>404</h1>
            <p>
                Cette page n'existe pas.
            </p>
            <Link to="/" className={classes.link}>
                <Button variant="contained" color="primary">
                    Retour à l'accueil
                </Button>
            </Link>
        </Container>
    );
}

export default NoMatchPage;
