import firebase from 'firebase';
import {
    END_OPEN_CHECKOUT,
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_SUCCESS, OPEN_CHECKOUT,
} from "./types";
import {loadStripe} from '@stripe/stripe-js';

export const fetchProducts = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_PRODUCTS,
        })

        let finalProduct;
        const prices = [];

        firebase.firestore().collection('products')
            .where('active', '==', true)
            .get()
            .then(querySnapshot => {

                querySnapshot.forEach(async (doc) => {
                    const priceSnap = await doc.ref
                        .collection('prices')
                        .orderBy('unit_amount')
                        .get();

                    const productId = doc.id;
                    const product = doc.data();
                    product.id = productId;
                    finalProduct = product
                    // Prices
                    priceSnap.docs.forEach((doc) => {
                        const priceId = doc.id;
                        const priceData = doc.data();
                        priceData.id = priceId;
                        prices.push(priceData);
                    });
                    dispatch({
                        type: FETCH_PRODUCTS_SUCCESS,
                        payload: {
                            product: finalProduct,
                            prices: prices,
                        }
                    })
                });
            });
    }
}

export const subscribe = (formulaId) => {
    return async (dispatch) => {
        console.log('subscribe')

        dispatch({
            type: OPEN_CHECKOUT,
        })

        const price = formulaId === 1 ? 'price_1HlhXJFnQ5O2F7Tbr22USdWZ' : 'price_1KSUdBFnQ5O2F7TbWPGdkyRE' // TODO ADD SECOND PRICE 47  EUROS ID HERE

        const Stripe = await loadStripe('pk_live_51HUcmOFnQ5O2F7TbDBDTm4cr8bAQfVJ1tz8BN04goTrT6mtZL9HeViKYgqImE82eIusbEtV7YkYrLEOkUM2hRbZ600qyQGbmoP'); // TODO CHANGE PROD KEY
        const {currentUser} = firebase.auth();

        const redirectUrlFail = `${window.location.origin}/subscription`;
        const redirectUrlSuccess = `${window.location.origin}/app`;
        firebase.firestore()
            .collection('customers')
            .doc(currentUser.uid)
            .collection('checkout_sessions')
            .add({
                price: price, // TODO CHANGE PRICE PROD ID
                success_url: redirectUrlSuccess,
                cancel_url: redirectUrlFail,
            })
            .then((docRef) => {
                // Wait for the CheckoutSession to get attached by the extension
                docRef.onSnapshot((snap) => {
                    const {sessionId} = snap.data();
                    console.log('session checkout ', snap.data())
                    console.log('session checkout', snap._key)

                    if (sessionId) {
                        dispatch({
                            type: END_OPEN_CHECKOUT,
                        })
                        // We have a session, let's redirect to Checkout
                        // Init Stripe
                        Stripe.redirectToCheckout({sessionId});
                    }
                });
            })
            .catch((e) => {
                console.log(e)
                dispatch({
                    type: END_OPEN_CHECKOUT,
                })
            })

    }
}
