import React from 'react';
import {makeStyles} from "@material-ui/core";
import Appbar from "../components/Appbar";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import {resetPassword, resetPasswordError} from "../actions";
import {connect} from "react-redux";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        left: '50%',
        marginLeft: -12,
        marginTop: 6,
    },
    container: {
        flex: 1
    }
}));

const FlexCenteredContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

function PasswordReset(props) {

    const classes = useStyles();
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState(true);
    const [emailTouched, setEmailTouched] = React.useState(false);

    React.useEffect(() => {
        props.resetPasswordError();
    }, []);

    const validateReset = (e) => {
        e.preventDefault();
        console.log(email);
        props.resetPassword(email);
    };

    const validateEmailFormat = (email) => {
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return regex.test(email);
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
        setEmailTouched(true);
        const isEmailValid = validateEmailFormat(e.target.value);
        if (!isEmailValid) {
            setEmailError(true)
        } else {
            setEmailError(false)
        }
    };

    return (
        <FlexCenteredContainer>
            <Grid alignItems="center" justify="center" className={classes.container} container>
                <Grid item>
                    <Container maxWidth="sm">
                        <Box px={3}>
                            <h1>Réinitialiser mon mot de passe</h1>

                            <Box pb={3} className={classes.form}>

                                <Alert severity="info" className={classes.alert}>
                                    <AlertTitle>Information</AlertTitle>
                                    Veuillez renseigner votre adresse email d'inscription. Nous vous enverrons un mail à
                                    cette adresse pour changer
                                    votre mot de passe.
                                </Alert>
                            </Box>

                            {
                                props.resetErrorMessage &&
                                <Box mb={3}>
                                    <Alert variant="filled" severity="error">{props.resetErrorMessage}</Alert>
                                </Box>
                            }

                            {
                                props.resetSuccessMessage &&
                                <Box mb={3}>
                                    <Alert variant="filled" severity="success">{props.resetSuccessMessage}</Alert>
                                </Box>
                            }

                            <form method="post" noValidate onSubmit={validateReset}>

                                <Box mb={2}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Adresse email"
                                        name="email"
                                        autoComplete="email"
                                        onChange={handleChange}
                                        value={email}
                                        error={emailTouched && emailError}
                                        helperText={emailTouched && emailError && "Adresse email invalide"}
                                    />

                                </Box>

                                <div className={classes.wrapper}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            props.resettingPassword
                                            || emailError
                                        }
                                        className={classes.submit}
                                    >
                                        Réinitialiser
                                    </Button>
                                    {props.resettingPassword &&
                                    <CircularProgress size={24} className={classes.buttonProgress}/>}
                                </div>
                                <Box mt={2}>
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        <Link to="/">Retour à la page de connexion</Link>
                                    </Typography>
                                </Box>


                            </form>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </FlexCenteredContainer>
    );
}

const mapStateToProps = ({auth}) => {
    const {
        resettingPassword,
        resetErrorMessage,
        resetSuccessMessage,
    } = auth;
    return {
        resettingPassword,
        resetErrorMessage,
        resetSuccessMessage,
    };
};

export default connect(mapStateToProps, {resetPasswordError,resetPassword})(PasswordReset);
