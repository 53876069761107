import React from 'react';
import Box from "@material-ui/core/Box";
import Game from "../components/Game";
import Layout from "../components/Layout";

class App extends React.Component {
    render() {
        return (
          <Layout isAdmin={this.props.isAdmin}>
              <Box style={{flex: 1, background: '#313954'}}>
                  <Game/>
              </Box>
          </Layout>
        );
    }
}

export default App;
