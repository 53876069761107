import React, { Component } from 'react';
import firebase from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress";

const style = {
    container: {
        position: 'relative',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
    },
    sentence: {
        color: 'darkgrey',
        fontSize: 13,
        marginTop: 24,
    }
};


const isUserLoggedIn = (ComposedComponent) => {

    class Authentication extends Component {
        state = {
            loading: true,
            userId: null,
        };

        b64DecodeUnicode = (str) => {
            return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }
            ).join(''));
        };

        componentDidMount() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    firebase.auth().currentUser.getIdToken(false)
                        .then((idToken) => {
                            const payload = JSON.parse(this.b64DecodeUnicode(idToken.split('.')[1]));
                            if (payload.admin) {
                                this.setState({
                                    loading: false,
                                    userId: user.uid,
                                });
                            } else {
                                this.props.history.push('/app');
                            }
                        })
                } else {
                    this.props.history.push('/');
                }
            });
        }

        render() {


            if (this.state.loading) {
                return (
                    <div style={style.container}>
                        <CircularProgress />
                        <p style={style.sentence}>Patientez, nous vérifions vos droits d'accès à la page...</p>
                    </div>
                )
            }
            return (
                <ComposedComponent {...this.props} userId={this.state.userId} />
            );
        }
    }

    return Authentication;
};

export default isUserLoggedIn;
