import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from "@material-ui/lab/AlertTitle";
import {connect} from "react-redux";
import {loginUser, resetLoginError} from "../actions";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © ADN '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: '#f2f2f2',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        //margin: theme.spacing(3, 0, 2),
    },
    errorMessage: {
        color: 'red',
        fontSize: 13,
    },
    wrapper: {
        marginTop: theme.spacing(3),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        left: '50%',
        marginLeft: -12,
        marginTop: 6,
    },
    introductionText: {
        color: '#666666'
    },
    alert: {
        // alignItems:'center',
        color: '#666666',
        width: '100%'
    }
}));

function SignIn(props) {
    const classes = useStyles();

    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);

    const validateLogin = (e) => {
        e.preventDefault();
        props.loginUser(email, password);
    };

    React.useEffect(() => {
        props.resetLoginError();
    }, []);

    return (
        <div className={classes.mainContainer}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Paper elevation={3}>
                    <Box p={3} className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h4">
                            Connexion
                        </Typography>
                        <Typography component="h4" variant="h6">
                            à ADN²
                        </Typography>

                        <Box pt={3} pb={1} className={classes.alert}>

                            <Alert severity="info" classes={{root: classes.alert}}>
                                <AlertTitle>Information</AlertTitle>
                                Veuillez renseigner vos identifiants.
                            </Alert>
                        </Box>

                        <form className={classes.form} method="post" noValidate onSubmit={validateLogin}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Adresse email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <div className={classes.wrapper}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={props.loadingLogin || email === null || password === null || email === '' || password === ''}
                                    className={classes.submit}
                                >
                                    Connexion
                                </Button>
                                {props.loadingLogin && <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </div>

                            <Box mt={2}>

                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" color="textSecondary" align="left">
                                            <Link to="/register">Pas encore inscrit ?</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" color="textSecondary" align="right">
                                            <Link to="/password-reset">Mot de passe oublié</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {
                                props.loginErrorMessage &&
                                <Box mt={3}>
                                    <Alert variant="filled" severity="error">{props.loginErrorMessage}</Alert>
                                </Box>
                            }
                        </form>
                    </Box>
                </Paper>
                <Box mt={8}>
                    <Copyright/>
                </Box>
            </Container>
        </div>

    );
}

const mapStateToProps = ({auth}) => {
    const {
        loadingLogin,
        loginErrorMessage
    } = auth;

    return {
        loadingLogin,
        loginErrorMessage
    };
};

const mapDispatchToProps = {
    loginUser,
    resetLoginError,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
