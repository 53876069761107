import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import QuestionMarkIcon from '@material-ui/icons/Help';
import HourglassIcon from '@material-ui/icons/HourglassFull';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import CheckIcon from '@material-ui/icons/Check';
import ChartIcon from '@material-ui/icons/Poll';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";
import MobileStepper from '@material-ui/core/MobileStepper';
import Hidden from "@material-ui/core/Hidden";

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#fff',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#fff',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
        left: 'calc(-85% + 20px)',
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const MyTrophyIcon = () => (
        <CheckIcon/>
    );

    const icons = {
        1: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        2: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        3: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        4: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        5: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        6: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        7: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        8: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    stepper: {
        background: '#dedede',
        color: '#fff',
    },
    label: {
        color: '#fff',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: 'white',
    },
    title: {
        fontSize: 26,
        fontWeight: 900,
        color: 'rgb(4, 210, 140)',
    },
    level: {
        fontSize: 18,
        margin: 0,
        fontWeight: 'bold',
    },
    levelIcon: {
        color: 'rgb(4, 210, 140)',
        fontSize: 32,
    },
    levelSpan: {
        color: 'rgb(4, 210, 140)',
    },
    mobileRoot: {
        width: 300,
        flex: 1,
    },
    mobileProgress: {
        width: '100% !important',
        height: 10,
    }
}));

function getSteps() {
    return [
        'Jour 1',
        'Jour 2',
        'Jour 3',
        'Jour 4',
        'Jour 5',
        'Jour 6',
        'Jour 7',
    ];
}

function getStepContent(step, classes) {
    const StepContent = ({stepNumber}) => (
        <Grid container alignItems="center" spacing={2}>
            <Grid item style={{display: 'flex'}}>
                <HourglassIcon className={classes.levelIcon}/>
            </Grid>
            <Grid item style={{flex: 1}}>
                <p className={classes.level}>
                    <span className={classes.levelSpan}>Jour {stepNumber === 7 ? 7 : stepNumber + 1}&nbsp;</span>
                    / 7
                </p>
            </Grid>
        </Grid>
    );

    const realStep = step > 7 ? 7 : step;
    return <StepContent stepNumber={realStep}/>;
}

const ProgressMobileStepper = ({step}) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <MobileStepper
                variant="progress"
                steps={77}
                position="static"
                activeStep={step}
                classes={{root: classes.mobileRoot, progress: classes.mobileProgress}}
            />
        </React.Fragment>

    );
}

export default function LevelStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    React.useEffect(() => {
        setActiveStep(props.step)
    });

    const realActiveStep = activeStep >= 7 ? 7 : activeStep;

    return (
        <React.Fragment>
            <Hidden mdUp>
                <h4 className={classes.title}>Jour d'essai : {realActiveStep}</h4>
                <ProgressMobileStepper step={realActiveStep - 1}/>
                <Box my={1}>
                    <div className={classes.instructions}>
                        {getStepContent(activeStep, classes, props.experience)}
                    </div>
                </Box>
            </Hidden>
            <Hidden smDown>
                <div className={classes.root}>
                    <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep}
                             connector={<ColorlibConnector/>}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel className={classes.label}
                                           StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        <Box px={5} mb={2}>
                            <div className={classes.instructions}>
                                {getStepContent(activeStep, classes, props.experience)}
                            </div>
                        </Box>
                    </div>
                </div>
            </Hidden>
        </React.Fragment>
    );
}
