import React from 'react';
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import {withStyles} from "@material-ui/core/styles";

import HexagoneIcon from '../assets/hexagon.png';
import PentagoneIcon from '../assets/pentagon.png';
import TriangleIcon from '../assets/triangle.png';
import SquareIcon from '../assets/square.png';
import CircleIcon from '../assets/circle.png';

import GameRulesDialog from "./GameRulesDialog";

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import * as firebase from "firebase";
import {connect} from "react-redux";
import {registerUser, resetRegisterError} from "../actions";


const MyFormLabel = ({text}) => (
    <FormLabel
        component="legend"
        style={{
            color: '#04D28C',
            fontWeight: 'bold',
            fontSize: 20,
            marginBottom: 24,
        }}
    >
        {text}
    </FormLabel>
);

const GreenRadio = withStyles({
    root: {
        color: 'white',
        '&$checked': {
            color: '#04D28C',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


const styles = theme => ({
    canvasContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'center',
        },
    },
    canvas: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
    },
    colorChoice: {
        [theme.breakpoints.down('sm')]: {
            order: 3,
            marginLeft: 32,
        },
        marginRight: 32,
    },
    shapeChoice: {
        [theme.breakpoints.down('sm')]: {
            order: 2,
            marginRight: 32,
        },
        marginLeft: 32,
    }
});

const codesLevels = [
    '3 1222 2121 2233 2312 3211 3323',
    '4 1124 1243 1331 1412 2222 2433 4113 4234 4342 4421',
    '5 1114 1451 2135 2224 2541 3153 3231 3344 3422 4142 4255 4311 4434 4523 5212 5333',
]


class GameVersionTest extends React.Component {

    state = {
        canvas: document.getElementById('canvas'),
        code: codesLevels[0],
        indexCode: 0,

        pionsBase: [],
        casesDisp: [],

        colorPion: 1,
        formPion: 1,
        pionsJoueur: [],

        game: null,
        transphere: null,
        coef: null,
        rayon: null,
        cote: null,

        gameRulesModalOpen: false,
        successOpen: false,
        defeatOpen: false,
        winAlertOpen: false,
        testFinished: false,

    };

    handleClose = () => {
        this.setState({
            successOpen: false,
            defeatOpen: false,
        });
    };

    renderSnack = (type, msg, open) => {
        return (
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert
                    onClose={this.handleClose}
                    style={{width: 300}}
                    variant="filled"
                    severity={type}
                >
                    <AlertTitle>{type == 'success' ? 'Bravo !' : 'Oups ...'}</AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        )
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.code !== prevState.code) {
            if (prevState.indexCode === 2) { // last grid
                this.setState({
                    testFinished: true,
                })
                const uid = firebase.auth().currentUser.uid;
                firebase.database().ref('/users')
                    .child(uid)
                    .update({
                        testFinished: true
                    })
            } else {
                console.log(this.state);
                this.drawPlateau(this.state.ctx, this.state.code);
            }
        }
    }

    componentDidMount() {
        this.props.resetRegisterError();

        const ctx = this.canvas.getContext('2d');

        const canvas = {
            width: 540,
            height: 540,
        };

        const cote = this.state.code[0];
        const coef = canvas.width / cote;
        const rayon = coef / 4;

        this.setState({
            ctx,
            cote,
            coef,
            rayon,
        })
        this.drawPlateau(ctx, this.state.code);
    }

    verif = () => {
        let test = 0;
        let testColor = [0, 0, 0, 0, 0];
        let testForm = [0, 0, 0, 0, 0];
        let pionsAll = [...this.state.pionsJoueur];
        this.state.pionsBase.forEach(e => {
            pionsAll.push({x: e.x, y: e.y, color: e.color, form: e.form});
        });
        //console.log('pionsAll', pionsAll)

        const cote = this.state.cote;
        // console.log('pionsAll', pionsAll);
        // console.log('cote ', cote);
        // console.log('this.state ', this.state);
        // console.log('Math.pow(cote, 2) ', Math.pow(cote, 2));

        if (pionsAll.length == Math.pow(cote, 2)) {
            pionsAll.forEach(e => {
                pionsAll.forEach(f => {
                    if ((e.x == f.x || e.y == f.y) && (e.color == f.color || e.form == f.form)) {
                        test++;
                    }
                });
                if (e.color == 1) {
                    testColor[0]++;
                } else if (e.color == 2) {
                    testColor[1]++;
                } else if (e.color == 3) {
                    testColor[2]++;
                } else if (e.color == 4) {
                    testColor[3]++;
                } else if (e.color == 5) {
                    testColor[4]++;
                }
                if (e.form == 1) {
                    testForm[0]++;
                } else if (e.form == 2) {
                    testForm[1]++;
                } else if (e.form == 3) {
                    testForm[2]++;
                } else if (e.form == 4) {
                    testForm[3]++;
                } else if (e.form == 5) {
                    testForm[4]++;
                }
            });
            testColor.forEach(e => {
                if (e !== cote * 1 && e !== 0) {
                    test = 100;
                }
            });
            testForm.forEach(e => {
                if (e !== cote * 1 && e !== 0) {
                    test = 100;
                }
            });
            if (test == pionsAll.length) {

                const canvas = {
                    width: 540,
                    height: 540,
                };

                const newIndex = this.state.indexCode + 1;

                const newCode = codesLevels[newIndex];
                const newCote = parseInt(this.state.cote) + 1;

                const coef = canvas.width / newCote;
                const rayon = coef / 4;

                console.log('newCode ', newCode);
                console.log('newCode ', newCote);
                console.log('coef ', coef);
                console.log('rayon ', rayon);

                this.setState({
                    code: newCode,
                    indexCode: newIndex,
                    cote: newCote,
                    coef,
                    rayon,
                    successOpen: true,
                    defeatOpen: false,
                });

            } else {
                this.setState({
                    defeatOpen: true,
                    defeatMessage: 'C\'est pas encore gagné !',
                });
            }
        } else {
            this.setState({
                defeatOpen: true,
                defeatMessage: 'Grille incomplète',
            });
        }
    }

    drawPlateau(ctx, fullCode) {

        //const {casesDisp, pionsBase} = this.state;

        const casesDisp = [];
        const pionsBase = [];

        this.setState({pionsJoueur: []});
        const canvas = {
            width: 540,
            height: 540,
        };

        ctx.beginPath();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // console.log(this.props);

        const cote = fullCode[0];
        let code = fullCode.substr(2);
        const coef = canvas.width / cote;
        const rayon = coef / 4;

        for (let i = 1; i <= cote; i++) {
            for (let j = 1; j <= cote; j++) {
                casesDisp.push({x: i, y: j});
                ctx.moveTo(0, i * coef);
                ctx.lineTo(canvas.width, i * coef);
                ctx.moveTo(i * coef, 0);
                ctx.lineTo(i * coef, canvas.height);
            }
        }
        ctx.moveTo(0, 0);
        ctx.lineTo(canvas.width, 0);
        ctx.moveTo(0, 0);
        ctx.lineTo(0, canvas.height);
        ctx.lineWidth = 3;
        ctx.strokeStyle = "white";
        ctx.stroke();
        while (code.length !== 0) {
            let index = 0;
            pionsBase.push({x: code[index], y: code[index + 1], color: code[index + 2], form: code[index + 3]});
            code = code.substr(5);
        }
        //console.log('pionsBase', pionsBase)
        code += cote;
        pionsBase.forEach(e => {
            //console.log('pion base : ', e)
            code += " " + e.x + e.y + e.color + e.form;
            if (e.form == 1) {
                this.drawCercle(e, ctx, coef, rayon);
            } else {
                this.drawPolygone(e, ctx, coef, rayon);
            }
            if (e.color == 1) {
                ctx.fillStyle = "red";
            } else if (e.color == 2) {
                ctx.fillStyle = "orange";
            } else if (e.color == 3) {
                ctx.fillStyle = "blue";
            } else if (e.color == 4) {
                ctx.fillStyle = "green";
            } else if (e.color == 5) {
                ctx.fillStyle = "purple";
            }
            ctx.fill();
            ctx.lineWidth = 5;
            ctx.strokeStyle = "black"
            ctx.stroke();
            for (let i = casesDisp.length - 1; i >= 0; i--) {
                if (e.x == casesDisp[i].x && e.y == casesDisp[i].y) {
                    casesDisp.splice(i, 1);
                }
            }
            // console.log('cases dispo :', casesDisp)
            this.setState({
                casesDisp,
                pionsBase,
            });
        });
    }

    drawPolygone(e, ctx, coef, rayon) {
        let angle = 195;
        let centreRep = {x: e.x * coef - coef / 2, y: e.y * coef - coef / 2};
        let pointActu = {x: centreRep.x + rayon, y: centreRep.y};
        pointActu = this.rotate(pointActu, centreRep, angle);
        angle = 360 / (e.form * 1 + 1);
        ctx.beginPath();
        ctx.moveTo(pointActu.x, pointActu.y);
        for (let i = 1; i <= e.form; i++) {
            pointActu = this.rotate(pointActu, centreRep, angle);
            ctx.lineTo(pointActu.x, pointActu.y);
        }
        ctx.closePath();
    }

    drawCercle(e, ctx, coef, rayon) {
        ctx.beginPath();
        ctx.arc(e.x * coef - coef / 2, e.y * coef - coef / 2, rayon, 0, Math.PI * 2);
    }

    rotate(pointActu, centreRep, angle) {
        let xM, yM, x, y;
        angle *= Math.PI / 180;
        xM = pointActu.x - centreRep.x;
        yM = pointActu.y - centreRep.y;
        x = xM * Math.cos(angle) + yM * Math.sin(angle) + centreRep.x;
        y = -xM * Math.sin(angle) + yM * Math.cos(angle) + centreRep.y;
        return ({x: Math.round(x), y: Math.round(y)});
    }

    drawPion = (f) => {
        const {
            ctx,
            coef,
            casesDisp,
            pionsJoueur,
            colorPion,
            formPion,
            rayon,
        } = this.state;

        let newPions = pionsJoueur;

        let selectedBlockX = Math.floor(f.nativeEvent.offsetX / coef) + 1;
        let selectedBlockY = Math.floor(f.nativeEvent.offsetY / coef) + 1;
        let isBlockAvailable = casesDisp.find((block) => block.x === selectedBlockX && block.y === selectedBlockY);

        if (isBlockAvailable) {
            const indexPionToRemove = newPions.findIndex((pion) => pion.x === selectedBlockX && pion.y === selectedBlockY);
            if (indexPionToRemove !== -1) {
                newPions.splice(indexPionToRemove, 1);
            }

            const newPion = {
                x: selectedBlockX,
                y: selectedBlockY,
                color: colorPion,
                form: formPion,
            };
            newPions.push(newPion);
        }
        newPions.forEach(e => {
            ctx.beginPath();
            ctx.arc(e.x * coef - coef / 2, e.y * coef - coef / 2, rayon * 1.33, 0, Math.PI * 2);
            ctx.fillStyle = 'lightgrey';
            ctx.fill();

            if (e.form == 1) {
                this.drawCercle(e, ctx, coef, rayon);
            } else {
                this.drawPolygone(e, ctx, coef, rayon);
            }
            if (e.color == 1) {
                ctx.fillStyle = "red";
            } else if (e.color == 2) {
                ctx.fillStyle = "orange";
            } else if (e.color == 3) {
                ctx.fillStyle = "blue";
            } else if (e.color == 4) {
                ctx.fillStyle = "green";
            } else if (e.color == 5) {
                ctx.fillStyle = "purple";
            }
            ctx.fill();
            ctx.lineWidth = 5;
            ctx.strokeStyle = "white"
            ctx.stroke();
        });
        this.setState({pionsJoueur: newPions})

    };

    handleChangeColor = (e) => {
        this.setState({
            colorPion: parseInt(e.target.value)
        });
    };

    handleChangeShape = (e) => {
        this.setState({
            formPion: parseInt(e.target.value)
        });
    };

    closeWinAlert = () => {
        this.props.recordUserGame(this.state.note, this.props.code);
    };

    openGameRulesModal() {
        this.setState({gameRulesModalOpen: true});
    }

    closeGameRulesModal = () => {
        this.setState({gameRulesModalOpen: false});
    }

    renderColorChoice() {
        const {classes} = this.props;

        const ColoredBox = ({color}) => (
            <Box style={{background: color, padding: '16px 32px'}}>
            </Box>
        );

        return (
            <Box pt={4} className={classes.colorChoice}>
                <FormControl component="fieldset">
                    <MyFormLabel text="Couleur"/>
                    <RadioGroup aria-label="couleur" name="color" value={this.state.colorPion}
                                onChange={this.handleChangeColor} style={{color: 'white'}}>
                        <FormControlLabel
                            value={1}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={red[800]}/>}
                            style={{margin: 0}}
                        />
                        <FormControlLabel
                            value={2}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={yellow[800]}/>}
                            style={{margin: 0}}
                        />
                        <FormControlLabel
                            value={3}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={blue[800]}/>}
                            style={{margin: 0}}
                        />
                        <FormControlLabel
                            value={4}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={green[800]}/>}
                            style={{margin: 0}}
                            disabled={this.state.cote < 4}
                        />
                        <FormControlLabel
                            value={5}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={purple[800]}/>}
                            style={{margin: 0}}
                            disabled={this.state.cote < 5}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        );
    }

    renderShapeChoice() {
        const {classes} = this.props;
        return (
            <Box pt={4} className={classes.shapeChoice}>
                <FormControl component="fieldset">
                    <MyFormLabel text="Forme"/>
                    <RadioGroup aria-label="forme" name="shape" value={this.state.formPion}
                                onChange={this.handleChangeShape} style={{color: 'white'}}>
                        <FormControlLabel
                            value={1}
                            control={<GreenRadio/>}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={CircleIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={2}
                            control={<GreenRadio/>}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={TriangleIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={3}
                            control={<GreenRadio/>}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={SquareIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={4}
                            control={<GreenRadio/>}
                            disabled={this.state.cote < 4}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={PentagoneIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={5}
                            control={<GreenRadio/>}
                            disabled={this.state.cote < 5}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={HexagoneIcon} alt=""/>}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        );
    }

    render() {

        const {
            defeatOpen,
            defeatMessage,
            successOpen,
        } = this.state;
        const {classes} = this.props;
        // console.log(this.state.code)

        return (
            <Grid container justify="center" alignItems="center">

                <GameRulesDialog testPage={true} handleClose={this.closeGameRulesModal}
                                 open={this.state.gameRulesModalOpen}/>
                {this.renderSnack('error', defeatMessage, defeatOpen)}
                {this.renderSnack('success', `Vous avez rempli le niveau ${this.state.indexCode}`, successOpen)}

                {
                    this.state.testFinished ?
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <Box pt={2} px={3}>
                                    <Typography variant="h4" style={{color: '#04D28C', fontWeight: 800,}}>
                                        Félicitations ! Vous avez réussi ce 1er Test.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={4} px={3}>
                                    <Typography variant="h5" style={{color: 'white', fontWeight: 800}}>
                                        Vous semblez être en forme, mais savez-vous qu'un entrainement régulier peut
                                        éloigner les petits et grands problèmes dûs aux pertes de mémoire et au déclin
                                        cognitif ? Dès maintenant vous pouvez commencer à améliorer votre mémoire et
                                        booster votre cerveau en le stimulant 10 à 20 minutes avec 3 grilles
                                        quotidiennes seulement avec le Meilleur des Jeux neuro-logiques Formes et
                                        Couleurs. Retrouvez une Méga Mémoire pour un Cerveau au Top de ses performances
                                        en profitant aujourd'hui de ce 2ème Test GRATUIT de 7 jours en cliquant sur le
                                        bouton ci-dessous ...
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box pt={5} mt={5}>

                                    <Button
                                        variant="contained"
                                        onClick={() => this.props.registerUser()}
                                        color="secondary"
                                        disabled={this.props.creatingUserLoading}
                                    >
                                        Je profite du 2ème test gratuit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        <React.Fragment>
                            <div className={classes.canvasContainer}>
                                {this.renderColorChoice()}
                                <Box pt={2} pb={5} pl={2} className={classes.canvas}>
                                    <canvas
                                        ref={ref => this.canvas = ref}
                                        onClick={this.drawPion}
                                        id="canvas"
                                        width="540"
                                        height="540"
                                        style={{
                                            background: 'lightgrey'
                                        }}
                                    >
                                    </canvas>
                                </Box>
                                {this.renderShapeChoice()}
                            </div>
                            <Grid container justify="center">
                                <Grid item>
                                    <Box pb={3}>
                                        <Button
                                            variant="contained"
                                            style={{color: 'white', background: '#04D28C'}}
                                            onClick={this.verif}
                                        >
                                            Verifier ma grille
                                        </Button>
                                        <Button
                                            style={{marginLeft: 32}}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.openGameRulesModal()}
                                        >
                                            Voir les règles
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }

            </Grid>
        )
    }
}

const mapStateToProps = ({auth}) => {
    const {
        creatingUserLoading,
        createErrorMessage,
        createSuccessMessage,
    } = auth;
    return {
        creatingUserLoading,
        createErrorMessage,
        createSuccessMessage,
    };
};

const WithStylesGame = withStyles(styles)(GameVersionTest);
export default connect(mapStateToProps, {resetRegisterError, registerUser})(WithStylesGame);
