import firebase from 'firebase';
import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
    FETCH_SINGLE_USER,
    FETCH_SINGLE_USER_SUCCESS,
    FETCH_SINGLE_USER_STATS,
    FETCH_SINGLE_USER_STATS_SUCCESS,
} from './types';
import moment from 'moment';


const m = moment().locale('fr');


export const fetchUsers = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_USERS,
        });

        try {
            firebase.database().ref('/users')
                .orderByChild('fullyRegistered')
                .equalTo(true)
                .once('value')
                .then((snap) => {
                    const usersObjects = snap.val();
                    const users = [];
                    for (let [key, user] of Object.entries(usersObjects)) {
                        user.id = key;
                        users.push(user)
                    }
                    console.log('ici .on')

                    dispatch({
                        type: FETCH_USERS_SUCCESS,
                        payload: users,
                    });
                })
        } catch (e) {
            console.log('la')
            dispatch({
                type: FETCH_USERS_FAIL,
                payload: e,
            });
        }


    }
};

export const fetchSingleUserData = (userId) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_SINGLE_USER,
        });

        // Fetch basic data
        firebase.database().ref('/users')
            .child(userId)
            .on('value',(snap) => {
                const userDetails = snap.val();
                dispatch({
                    type: FETCH_SINGLE_USER_SUCCESS,
                    payload: userDetails,
                });
            })
    }
};


function createMonthesArray (){
    let data = [];
    const allMonthes = moment.months();
    data[0] = ['Mois', 'Nombre de grilles terminées / mois'];
    for (let i = 1 ; i <= 12; i++){
        data[i] = [allMonthes[i -1 ] , 0];
    }
    return data;
}

export const fetchUserGameStats = (userId) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_SINGLE_USER_STATS,
        });



        // Fetch basic data
        firebase.database().ref('/userGames')
            .child(userId)
            .once('value')
            .then((snap) => {
                const userGamesObjects = snap.val();
                const userGames = [];
                if (userGamesObjects) {

                    for (let [key, game] of Object.entries(userGamesObjects)) {
                        game.id = key;
                        userGames.push(game)
                    }

                    let data = createMonthesArray();

                    for (const game of userGames) {
                        const actualYear = moment().year();
                        const gameDate = moment(game.date);
                        const month = gameDate.format('MMMM');
                        const monthNumber = gameDate.month() + 1;
                        const year = gameDate.year();

                        //if games older than this year, not in data
                        if (year === actualYear) {
                            let numberOfGamesForMonth = data[monthNumber][1] ? data[monthNumber][1] :  0;
                            let newNumberOfGamesForMonth = numberOfGamesForMonth + 1;
                            data[monthNumber] = [month, newNumberOfGamesForMonth];
                        }
                    }
                    dispatch({
                        type: FETCH_SINGLE_USER_STATS_SUCCESS,
                        payload: data,
                    });
                }else{
                    dispatch({
                        type: FETCH_SINGLE_USER_STATS_SUCCESS,
                        payload: [],
                    });
                }
            })
    }
};
