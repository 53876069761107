import React from 'react';
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Layout from "../components/Layout";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(2)
    },
}));

function LegalNotice() {

    const classes = useStyles();

    return (
        <Layout>
            <Container maxWidth="md" className={classes.container}>
                <h1>Mentions légales</h1>
                <h2>Éditeur</h2>
                <p>
                    Le site agitateur-de-neurones.net est édité par la Sasu "Agitateurs De Neurones (A-D-N)".
                </p>
                <p>
                    120 Porte de la Bastide 40300 Hastingues
                </p>

                <p> 0 771 771 111
                </p>

                <p> N° SIRET 878 496 447 - RCS Dax</p>


                <h2> Directeur de la publication</h2>

                M TORRES Jean Luc

                <h2>
                    Traitement des données à caractère personnel
                </h2>

                Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux
                fichiers et aux libertés, vous disposez d’un droit d’accès, de modification, de rectification et de
                suppression aux données qui vous concernent. Vous pouvez exercer ce droit, en envoyant un courrier par
                voie électronique ou postale à la Sasu " Agitateurs De Neurones (A-D-N)" en justifiant de votre
                identité.

                <h2>
                    Accessibilité
                </h2>

                Ce site a été conçu dans le respect des standards du web définis par le W3C, notamment des directives
                pour l’accessibilité aux contenus web édictées par l’initiative WAI.

                <h2>
                    Hébergement
                </h2>

                <p>
                    Le site internet de la Sasu "Agitateurs De Neurones (A-D-N)" est hébergé par :
                </p>

                <p>
                    LWS (Ligne Web Services)
                </p>
                <p>
                    10 rue Penthievre 75008 Paris
                </p>
                <p>
                    0 892 700 479
                </p>

                <h2>
                    Conception et réalisation
                </h2>
                <p>
                    Jean Luc TORRES avec LWS
                </p>
            </Container>
        </Layout>
    );
}

export default LegalNotice;
