import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
    top: {
        color: '#04D28C',
        animationDuration: '550ms',
    },
    circle: {
        strokeLinecap: 'round',
    },
    percentage: {
        fontSize: 30,
        fontWeight: 900,
        color: '#04D28C',
    }
}));

function CircularProgressWithLabel(props) {
    const classes = useStylesFacebook();

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress  className={classes.top}
                               classes={{
                                   circle: classes.circle,
                               }}
                               variant="static" {...props} size={100} thickness={5} />

            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography className={classes.percentage} variant="caption" component="div" color="textSecondary">
                        {`${Math.round(
                            props.label,
                        )}`}
                   </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and static variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function CircularStatic( props ) {

    return <CircularProgressWithLabel value={100} label={props.level} />;
}
