import React from "react";
import Chart from "react-google-charts";
import Box from "@material-ui/core/Box";

export default class UserChart extends React.Component {
    render() {
        return (
            <Chart
                width={'600px'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Loading Chart</div>}
                data={this.props.data}
                options={{
                    // Material design options
                    chart: {
                        title: 'Statistiques parties utilisateur',
                        subtitle: 'Nombre de parties par mois cette année',
                    },
                }}
                // For tests
                rootProps={{'data-testid': '2'}}
            />
        );
    }
}
