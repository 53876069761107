import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as firebase from "firebase";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import logo from '../assets/adnlogo.png';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#04D28C'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: 24,
    },
    link: {
        color: 'white',
        flexGrow: 1,
    },
    btn: {
        color: 'white',
        border: '1px solid white'
    },
}));

export default function ButtonAppBar(props) {
    const classes = useStyles();

    const user = firebase.auth().currentUser;
    return (
        <AppBar position="static" className={classes.root}>
            <Toolbar>
                <Link to={user ? '/app' : '/'} className={classes.link}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <img src={logo} style={{maxHeight: 50}} alt=""/>
                        </Grid>
                    </Grid>
                </Link>

                {
                    props.isAdmin &&
                    <Box mr={4}>
                        <Link className={classes.link} to="/admin">
                            <Button
                                variant="outlined"
                                className={classes.btn}
                            >
                                Page admin
                            </Button>
                        </Link>
                    </Box>
                }
                {
                    user &&
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => firebase.auth().signOut()}
                    >
                        Deconnexion
                    </Button>
                    /*    <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to="/login"
                        >
                            Deja inscrit ? Connexion
                        </Button>*/
                }
            </Toolbar>
        </AppBar>
    );
}
