import React from 'react';
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Layout from "../components/Layout";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(2),
    },
}));

function PrivacyPolicy() {

    const classes = useStyles();

    return (
        <Layout>
            <Container maxWidth="md" className={classes.container}>
                <h1>Politique de confidentialité</h1>

                <h2>
                    COOKIES ET DONNÉES PERSONNELLES
                </h2>

                <h2>
                    QU’EST-CE QU’UN COOKIE ?
                </h2>

                <p>
                    Un cookie est un fichier texte qui enregistre des informations relatives à la navigation sur un site
                    Internet. Le cookie ne peut pas être utilisé pour récupérer des données sur le disque dur de
                    l’utilisateur, installer un virus, lire l’adresse e-mail de l’utilisateur ou encore se procurer des
                    informations personnelles.
                </p>

                <p>
                    La plupart des sites Web, y compris celui de "Agitateurs De Neurones (A-D-N)", utilisent des
                    cookies.
                </p>

                <h2>
                    A QUOI SERVENT LES COOKIES SUR LE SITE DE "Agitateur De Neurones (A-D-N) ?
                </h2>

                <p>
                    Les données collectées par "Agitateurs De Neurones (A-D-N)" sont les suivantes :
                </p>
                <ul>

                    <li> L’adresse IP, c’est à dire l’adresse de l’ordinateur</li>
                    <li> La date et l’heure de connexion d’un appareil (Terminal) à notre site</li>
                    <li> L’url, l’adresse web de la page Internet de provenance de l’appareil accédant à notre site</li>
                    <li> L’url de la page Internet accédée par l’appareil consultant notre site</li>
                    <li> Le type de système d’exploitation utilisé par l’appareil (Windows, MacOs, Linux, Unix, BeOS…)
                    </li>
                    <li> Le type et la version du logiciel de navigation utilisé par l’appareil (Internet Explorer,
                        Safari, Firefox, Chrome, Opera…)
                    </li>
                    <li> La langue d’utilisation du logiciel de navigation utilisé par l’appareil</li>
                    <li> L’identifiant et le contenu d’un Cookie stocké par Séquano Aménagement dans l’appareil</li>
                    <li> Le type d’appareil utilisé lors de la navigation (ordinateur, téléphone, tablette…)</li>

                </ul>


                Les cookies émis par "Agitateurs De Neurones (A-D-N)" permettent de :

                <ul>

                </ul>
                <li>
                    Etablir des statistiques et volumes de fréquentation et d’utilisation des divers éléments composant
                    notre site (rubriques et contenus visités, parcours, etc.), nous permettant d’améliorer l’intérêt et
                    l’ergonomie de nos services
                </li>


                <li>
                    Adapter la présentation de notre site aux préférences d’affichage de votre terminal web ou mobile
                    (résolution d’affichage, système d’exploitation utilisé…) lors de vos visites sur notre site
                </li>

                <li>
                    Mémoriser des informations relatives à un formulaire que vous avez rempli sur notre site (accès aux
                    différents espaces)
                </li>

                <li>
                    Vous permettre d’accéder à des espaces réservés et personnels de notre site, tels que notre espace
                    "jeu
                    ADN²", grâce à des identifiants ou des données que vous nous avez éventuellement confiés

                </li>

                <li>
                    Mettre en œuvre des mesures de sécurité, par exemple lorsqu’il vous est demandé de vous connecter à
                    nouveau à un contenu ou à un service après un certain laps de temps
                </li>


                <p>
                    Les cookies émis sur notre site par des tiers du fait d’applications tierces intégrées à notre site
                    L’émission et l’utilisation de cookies sur notre site par des tiers, sont soumises aux politiques de
                    protection de la vie privée de ces tiers. Nous vous informons de l’objet des cookies dont nous avons
                    connaissance et des moyens dont vous disposez pour effectuer des choix à l’égard de ces cookies.
                </p>

                <p>
                    Nous sommes susceptibles d’inclure sur notre site, des applications informatiques émanant de tiers,
                    qui
                    vous permettent de partager des contenus du site avec d’autres personnes ou de faire connaître à ces
                    autres personnes votre consultation ou votre opinion concernant un contenu de notre site. Tel est
                    notamment le cas des boutons « Partager », « J’aime », issus de réseaux sociaux tels que « Facebook
                    », «
                    Twitter », etc.
                </p>

                <p>
                    Ce type de bouton applicatif peut permettre au réseau social de vous identifier si votre compte au
                    réseau social est activé sur votre terminal (session ouverte) et de suivre votre navigation sur
                    notre
                    site.
                </p>

                <p>
                    Nous n’avons aucun contrôle sur le processus employé par les réseaux sociaux pour collecter des
                    informations relatives à notre navigation sur notre site et associées aux données personnelles dont
                    ils
                    disposent. Nous vous invitons à consulter les politiques de protection de la vie privée de ces
                    réseaux
                    sociaux afin de prendre connaissance des finalités d’utilisation, notamment publicitaires, des
                    informations de navigation qu’ils peuvent recueillir grâce à ces boutons applicatifs. Ces politiques
                    de
                    protection doivent notamment vous permettre d’exercer vos choix auprès de ces réseaux sociaux,
                    notamment
                    en paramétrant vos comptes d’utilisation de chacun de ces réseaux.
                </p>

                <h2>
                    VOS CHOIX CONCERNANT LES COOKIES
                </h2>

                <p>
                    L’utilisateur peut s’opposer à l’enregistrement de cookies en configurant son navigateur. Cependant,
                    si
                    l’utilisateur décide de supprimer ses cookies, il devra par la suite renouveler l’opération. S’il
                    décide
                    de se rendre sur un autre navigateur, il faut à nouveau supprimer les cookies sur ce deuxième
                    navigateur.
                </p>


                <p>
                    Voici la démarche à suivre pour supprimer les cookies sur les différents navigateurs :
                </p>

                <h3>
                    Pour Mozilla firefox :
                </h3>

                http://support.mozilla.org/fr/kb/activer-desactiver-cookies?redirectlocale=fr&redirectslug=Activer+et+d%C3%A9sactiver+les+cookies

                Choisissez le menu « outil » puis « options »
                Cliquez sur l’icône « vie privée »
                Repérez le menu « cookie » et sélectionnez les options qui vous conviennent

                <h3>
                    Pour Microsoft Internet Explorer :
                </h3>

                http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies

                Choisissez le menu « outil » puis « options Internet »
                Cliquez sur l’icône « Confidentialité »
                Sélectionnez le niveau souhaité à l’aide du curseur

                <h3>
                    Pour Chrome :
                </h3>

                https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en

                Allez dans le menu Chrome / Préférences
                Cliquez sur « Afficher les paramètres avancés »
                Cliquez sur « Paramètres de contenu… » dans la partie confidentialité
                Cliquez sur « Cookies et données de site… » dans la nouvelle fenêtre
                Cliquez sur « Tout supprimer » et validez en cliquant sur « OK »

                <h3>
                    Pour Safari :
                </h3>

                http://www.apple.com/support/?path=Safari/3.0/fr/9277.html

                Allez dans le menu Safari / Préférences
                Cliquez sur « confidentialité »
                Dans « Bloquer les cookies », cochez « Toujours »

                <h3>
                    Plus d’informations sur les cookies avec la CNIL :
                </h3>

                <p>
                    Tout savoir sur les cookies : http://www.cnil.fr/vos-droits/vos-traces/les-cookies/#c5554

                </p>
                <p>
                    Démarches à suivre pour limiter vos traces sur le web :

                </p>
                <p>
                    http://www.cnil.fr/vos-droits/vos-traces/les-cookies/conseils-aux-internautes/

                </p>
                <p>
                    Outil de visualisation qui identifie en temps réel les cookies qui transmettent des informations :

                </p>
                <p>
                    http://www.cnil.fr/vos-droits/vos-traces/les-cookies/telechargez-cookieviz/

                </p>
                <h2>
                    SI VOUS PARTAGEZ L’UTILISATION DE VOTRE TERMINAL
                </h2>

                <p>
                    Si votre terminal est utilisé par plusieurs personnes et lorsqu’un même terminal dispose de
                    plusieurs
                    logiciels de navigation, nous ne pouvons pas nous assurer de manière certaine que les services
                    destinés
                    à votre terminal correspondent bien à votre propre utilisation de ce terminal et non à celle d’un
                    autre
                    utilisateur de ce terminal.
                </p>

                <p>
                    Le cas échéant, le partage avec d’autres personnes de l’utilisation de votre terminal et la
                    configuration des paramètres de votre navigateur à l’égard des cookies relèvent de votre libre choix
                    et
                    de votre responsabilité.
                </p>

                <h2>
                    DONNÉES PERSONNELLES
                </h2>

                <p>
                    Dans la mesure où vous nous avez fourni des données personnelles vous concernant, notamment vos
                    coordonnées électroniques, lors de votre inscription ou de votre accès à l’un de nos services, nous
                    sommes susceptibles, sous réserve de vos choix, d’associer des informations de navigation relatives
                    à
                    votre terminal, traitées par les cookies que nous émettons, avec vos données personnelles, afin de
                    vous
                    adresser des prospections électroniques.

                </p>

                <p>
                    Vous pourrez, à tout moment, nous demander de ne plus recevoir des prospections adaptées aux
                    informations de navigation de votre terminal, en nous contactant directement et gratuitement, ou au
                    moyen du lien de désinscription inclus dans toute prospection que nous serions susceptible de vous
                    adresser par courrier électronique.
                </p>


                <p>
                    Par ailleurs, dans l’hypothèse où nous envisagerions d’obtenir auprès d’un tiers (prestataire de
                    publicité ciblée, régie publicitaire, etc.), des informations de navigation de votre terminal que
                    nous
                    pourrions associer aux données personnelles que vous nous avez fournies, nous solliciterions
                    préalablement votre accord explicite avant de procéder à une telle association et de vous adresser
                    des
                    publicités ou des prospections qui en résulteraient.
                </p>

            </Container>
        </Layout>
    );
}

export default PrivacyPolicy;
