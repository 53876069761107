import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_SUCCESS,
    OPEN_CHECKOUT,
    END_OPEN_CHECKOUT,
} from "../actions/types";

const INITIAL_STATE = {
    product: {},
    prices: [],
    productLoading: false,
    checkoutLoading: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return {
                ...state,
                productLoading: true,
            };
        case FETCH_PRODUCTS_SUCCESS:
            const {product, prices} = action.payload;
            return {
                ...state,
                product,
                prices,
                productLoading: false,
            };
        case OPEN_CHECKOUT: {
            return {
                ...state,
                checkoutLoading: true,
            };
        }
        case END_OPEN_CHECKOUT : {
            return {
                ...state,
                checkoutLoading: false,
            };
        }
        default:
            return {...state};
    }
}
