import React from 'react';
import Container from "@material-ui/core/Container";
import Layout from "../components/Layout";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {registerForTesting, fetchProducts} from "../actions";
import {withStyles} from '@material-ui/core/styles';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import * as firebase from "firebase";
import Snackbar from "@material-ui/core/Snackbar";
import RegistrationStepperCard from "../components/RegistrationStepperCard";
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowNextIcon from '@material-ui/icons/ArrowForward';

//import registerStepImage1 from '../assets/register-step-1.png';
//import registerStepImage2 from '../assets/register-step-2.png';
import logo from '../assets/adnlogo.png';

import registerStepImage1 from '../assets/mockupregister.jpg';
import registerStepImage2 from '../assets/mockup.jpg';
import registerStepImage3 from '../assets/register-step-3.png';
import Grid from "@material-ui/core/Grid";
import AlertTitle from "@material-ui/lab/AlertTitle";


import {styled} from "@material-ui/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Paper from "@material-ui/core/Paper";

const StyledStepLabel = styled(StepLabel)({
    "& .MuiStepLabel-active": {
        color: "#3f51b5",
        fontWeight: 'bold',
        fontSize: 24,
    }
});

const useStyles = ((theme) => ({
    root: {
        background: '#f3f3f3',
        flex: 1,
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        fontSize: theme.typography.pxToRem(28),
        fontWeight: 900,
        textAlign: 'center',
    },
    subtitle: {
        margin: '24px 0 20px 0',
        fontSize: theme.typography.pxToRem(40),
        textAlign: 'center',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 'bold',
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    logo: {
        maxWidth: 100,
        marginTop: theme.spacing(4)
    }
}));


class GameTest extends React.Component {
    state = {
        email: '',
        emailError: false,
        passwordConfirm: '',
        password: '',
        submitted: false,
        expanded: false,
        activeStep: 0,
        emailValidatedError: true,
        validatedEmailClicked: false,
    }

    setExpanded = (panel) => {
        this.setState({expanded: panel})
    }

    handleChange = (panel) => (event, isExpanded) => {
        this.setExpanded(isExpanded ? panel : false);
    };

    handleNextStep = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        })

        /* const currentUser = firebase.auth().currentUser;

         if (currentUser) {
             currentUser.reload()
                 .then(() => {
                     const reloadedUser = firebase.auth().currentUser;
                     this.setState({validatedEmailClicked: true});
                     if (reloadedUser.emailVerified) {
                         this.setState({
                             activeStep: this.state.activeStep + 1,
                             emailValidatedError: false
                         })
                         this.props.history.push('/login')
                     } else {
                         this.setState({emailValidatedError: true})
                     }
                 })
         }*/

    }

    handleChangeMail = (e) => {
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
        if (!emailRegex.test(e.target.value)) {
            this.setState({emailError: true})
        } else {
            this.setState({emailError: false})
        }
        this.setState({email: e.target.value})
    };

    handlePassChange = (e) => {
        const {value, name: fieldName} = e.target;

        if (fieldName === 'password') {
            this.setState({password: value});
        } else if (fieldName === 'passwordConfirm') {
            this.setState({passwordConfirm: value});
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(firebase.auth().currentUser)
        if (!prevProps.createTestErrorMessage && this.props.createTestErrorMessage) {
            this.setState({activeStep: 0,email: ''})
        }
        if (!prevProps.createTestSuccessMessage && this.props.createTestSuccessMessage) {
            this.setState({activeStep: 2})
        }
    }

    componentDidMount() {
        this.props.fetchProducts();
        const user = firebase.auth().currentUser;
        if (user && user.emailVerified) {
            this.setState({
                activeStep: this.state.activeStep + 1,
                emailValidatedError: false
            })
        }
    }

    handleSubmit = () => {
        this.setState({submitted: true})

        this.props.registerForTesting(this.state.email, this.state.password);
    }

    renderOnBoardingSteps() {
        return (

            <Grid container direction="row" spacing={2} justify="center">
                <Grid item xs={12} sm={6}>
                    <RegistrationStepperCard
                        card={{
                            title: 'Mes identifiants',
                            content: 'Je renseigne les identifiants (adresse e-mail et mot de passe) pour créer mon compte via le formulaire ci-dessous. Ces identifiants vous permettront de vous connecter ultérieurement.',
                            imageUrl: registerStepImage1
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RegistrationStepperCard
                        card={{
                            title: 'Je valide mon email',
                            content: 'Après avoir renseigné mon adresse email, vous recevrez un mail qui contient un lien pour valider votre compte. Ce lien vous amène vers la page ci-dessus en image. Cliquez sur le bouton pour revenir sur le site ADN²',
                            imageUrl: registerStepImage2
                        }}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <RegistrationStepperCard
                        card={{
                            title: 'Je commence le test du jeu',
                            content: 'Après avoir validé mon email, je clique sur le bouton "J\'ai validé mon email". Le jeu commence !',
                            imageUrl: registerStepImage3
                        }}
                    />
                </Grid>*/}
            </Grid>
        )
    }

    renderSubtitle() {
        const {classes} = this.props;
        let subtitle;
        if (this.state.activeStep === 0) {
            subtitle = 'Tout d\'abord, saisissez votre adresse e-mail.';
        } else if (this.state.activeStep === 1) {
            subtitle = 'Super ! Maintenant, il faut choisir un mot de passe.';
        } else if (this.state.activeStep === 2) {
            subtitle = this.props.createTestSuccessMessage;
        }

        return (
            <Container maxWidth="sm">
                <h2 className={classes.subtitle}>
                    {subtitle}
                </h2>
            </Container>
        )
    }


    renderBottomButton() {
        if (this.state.activeStep === 0) {
            return (
                <Button
                    onClick={() => this.handleNextStep()}
                    disabled={this.state.emailError || !this.state.email}
                    loading={this.props.creatingTestUserLoading}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    endIcon={<ArrowNextIcon/>}
                >
                    Continuer
                </Button>
            )
        } else if (this.state.activeStep === 1) {
            return (
                <Button
                    style={{marginLeft: 32}}
                    onClick={() => this.handleSubmit()}
                    disabled={
                        this.props.creatingTestUserLoading
                        || this.state.password.length < 6
                        || this.state.passwordConfirm !== this.state.password
                    }
                    loading={this.props.creatingTestUserLoading}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    endIcon={<ArrowNextIcon/>}
                >
                    Jeu test
                </Button>
            )
        }

    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <div>

                    <Grid container alignItems="center" justify="center" direction="column">
                        <Grid item>
                            <img src={logo} alt="" className={classes.logo}/>
                        </Grid>
                        {/* <Grid item>
                            <h1 className={classes.title}>Agitateur De Neurones</h1>
                        </Grid>*/}
                    </Grid>

                    {this.renderSubtitle()}

                    <Box pb={4}>
                        <div>


                            <Snackbar
                                open={this.state.emailValidatedError && this.state.validatedEmailClicked}
                                autoHideDuration={6000}
                            >
                                <Alert
                                    variant="filled"
                                    severity="error"
                                >
                                    Vous n'avez pas validé votre email!
                                </Alert>
                            </Snackbar>



                            <Container maxWidth="sm">
                                {
                                    this.props.createTestErrorMessage && this.state.submitted &&
                                    <Box py={2}>
                                        <Alert variant="filled" severity="error">
                                            {this.props.createTestErrorMessage}
                                        </Alert>
                                    </Box>
                                }
                                <Paper elevation={4}>
                                    <Stepper activeStep={this.state.activeStep} orientation="vertical">
                                        <Step key={1}>
                                            <StyledStepLabel>Je renseigne mon e-mail</StyledStepLabel>
                                            <StepContent>
                                                <Box mb={2}>
                                                    <TextField
                                                        onChange={(e) => this.handleChangeMail(e)}
                                                        required
                                                        size="large"
                                                        error={this.state.emailError}
                                                        helperText={this.state.emailError ? 'Email invalide' : 'Veuillez renseigner votre adresse e-mail'}
                                                        fullWidth
                                                        label="Votre adresse e-mail"
                                                    />
                                                </Box>

                                            </StepContent>
                                        </Step>

                                        <Step key={2}>
                                            <StyledStepLabel>Je choisis un mot de passe</StyledStepLabel>
                                            <StepContent>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Mot de passe"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    onChange={(e) => this.handlePassChange(e)}
                                                    value={this.state.password}
                                                    error={this.state.password && this.state.password.length < 6}
                                                    helperText={this.state.password.length < 6 && "Le mot de passe doit contenir 6 caractères minimum"}

                                                />
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="passwordConfirm"
                                                    label="Confirmation mot de passe"
                                                    type="password"
                                                    id="passwordConfirm"
                                                    autoComplete="current-passwordConfirm"
                                                    onChange={(e) => this.handlePassChange(e)}
                                                    value={this.state.passwordConfirm}
                                                    error={this.state.passwordConfirm !== this.state.password}
                                                    helperText={this.state.passwordConfirm !== this.state.password && "Les deux mots de passe sont différents"}
                                                />
                                            </StepContent>
                                        </Step>


                                        <Step key={3}>
                                            <StyledStepLabel>Je teste le jeu!</StyledStepLabel>
                                            <StepContent>
                                                <Typography style={{fontSize: 24}}>
                                                    Nous avons envoyé un e-mail de confirmation à
                                                    l’adresse <b>{this.state.email}</b>.
                                                    Veuillez cliquer sur le lien qu'il contient et vous accederez au jeu
                                                    !
                                                </Typography>
                                            </StepContent>
                                        </Step>
                                    </Stepper>


                                    <Container maxWidth="sm" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingBottom: 16,
                                    }}>

                                        {
                                            this.state.activeStep === 1 &&
                                            <Button
                                                onClick={() => this.setState({activeStep: this.state.activeStep - 1})}
                                                color="default"
                                                size="large"
                                                startIcon={<ArrowBackIcon/>}
                                            >
                                                Retour
                                            </Button>

                                        }

                                        {this.renderBottomButton()}
                                    </Container>


                                </Paper>

                                <Box pt={3} pb={5}>
                                    <Alert
                                        severity="info"
                                    >
                                        <AlertTitle>INFORMATION</AlertTitle>
                                        <p style={{fontWeight: 'bold', fontSize: 16, color: '#414141'}}>
                                            Si vous avez déja effectué votre inscription sur ADN²,&nbsp;
                                            <Link to="/" onClick={() => firebase.auth().signOut()}>
                                                cliquez ici
                                            </Link>
                                        </p>
                                    </Alert>


                                </Box>

                            </Container>

                        </div>
                    </Box>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({auth}) => {
    const {
        creatingTestUserLoading,
        createTestErrorMessage,
        createTestSuccessMessage,
    } = auth;
    return {
        creatingTestUserLoading,
        createTestErrorMessage,
        createTestSuccessMessage,
    };
};

const WithStylesGameTest = withStyles(useStyles)(GameTest);
export default connect(mapStateToProps, {registerForTesting, fetchProducts})(WithStylesGameTest);
