import {
    LOGIN_USER,
    LOGIN_USER_FAIL,
    LOGIN_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_FAIL,
    REGISTER_USER_SUCCESS,
    RESET_LOGIN_ERROR,
    RESET_REGISTER_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_RESET_PASSWORD_FORM,
    REGISTER_TEST_USER,
    REGISTER_TEST_USER_SUCCESS,
    REGISTER_TEST_USER_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
    user: null,
    userInfos: null,

    loadingLogin: false,
    loginErrorMessage: '',

    creatingUserLoading: false,
    createErrorMessage: '',
    createSuccessMessage: '',

    creatingTestUserLoading: false,
    createTestErrorMessage: '',
    createTestSuccessMessage: '',

    resettingPassword: false,
    resetErrorMessage: '',
    resetSuccessMessage: '',
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET_LOGIN_ERROR:
            return {...state, loadingLogin: false, loginErrorMessage: ''};
        case LOGIN_USER:
            return {...state, loadingLogin: true, loginErrorMessage: ''};
        case LOGIN_USER_SUCCESS:
            const {user, userInfos} = action.payload;
            return {
                ...state, user, userInfos, loadingLogin: false, loginErrorMessage: '',
            };
        case RESET_REGISTER_ERROR:
            return {...state, creatingUserLoading: false, createErrorMessage: '', createSuccessMessage: ''};
        case REGISTER_USER:
            return {...state, creatingUserLoading: true, createErrorMessage: '', createSuccessMessage: ''};
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                creatingUserLoading: false,
                createErrorMessage: '',
                createSuccessMessage: 'Le compte a été crée avec succès',
            };
        case REGISTER_USER_FAIL:
            return {
                ...state,
                creatingUserLoading: false,
                createSuccessMessage: '',
                createErrorMessage: action.payload,
            };
        case REGISTER_TEST_USER:
            return {...state, creatingTestUserLoading: true, createTestErrorMessage: '', createTestSuccessMessage: ''};
        case REGISTER_TEST_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                creatingTestUserLoading: false,
                createTestErrorMessage: '',
                createTestSuccessMessage: 'Email envoyé, en attente de confirmation de votre part.',
            };
        case REGISTER_TEST_USER_FAIL:
            return {
                ...state,
                creatingTestUserLoading: false,
                createTestSuccessMessage: '',
                createTestErrorMessage: action.payload,
            };
        case LOGIN_USER_FAIL:
            return {...state, loadingLogin: false, loginErrorMessage: action.payload};

        case RESET_PASSWORD:
            return {...state, resettingPassword: true, resetErrorMessage: '', resetSuccessMessage: ''};
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                resettingPassword: false,
                resetErrorMessage: action.payload.message,
                resetSuccessMessage: ''
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resettingPassword: false,
                resetErrorMessage: '',
                resetSuccessMessage: 'L\'email de réinitialisation a bien été envoyé'
            };
        case RESET_RESET_PASSWORD_FORM:
            return {...state, resettingPassword: false, resetErrorMessage: '', resetSuccessMessage: ''};

        default:
            return {...state};
    }
}
