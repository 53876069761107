import React from 'react';
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {connect} from "react-redux";
import firebase from 'firebase';


import HexagoneIcon from '../assets/hexagon.png';
import PentagoneIcon from '../assets/pentagon.png';
import TriangleIcon from '../assets/triangle.png';
import SquareIcon from '../assets/square.png';
import CircleIcon from '../assets/circle.png';

import GameWinAlert from "./GameWinAlert";
import Backdrop from '../components/Backdrop';

import {recordUserGame, decrementUserLevel, fetchUserLevel} from "../actions";
import {getCode, calculateLevelFromXp, getCustomClaimRole} from "../services";
import {CircularProgress} from "@material-ui/core";
import CircularLevelProgress from "./CircularLevelProgress";
import {withStyles} from "@material-ui/core/styles";
import LevelStepper from "./LevelStepper";
import LevelUpAnimation from "./LevelUpAnimation";
import FreeTrialLeftDays from "./FreeTrialLeftDays";
import GameRulesDialog from "./GameRulesDialog";
import moment from 'moment';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import {number} from "prop-types";


const MyFormLabel = ({text}) => (
    <FormLabel
        component="legend"
        style={{
            color: '#04D28C',
            fontWeight: 'bold',
            fontSize: 20,
            marginBottom: 24,
        }}
    >
        {text}
    </FormLabel>
);

const GreenRadio = withStyles({
    root: {
        color: 'white',
        '&$checked': {
            color: '#04D28C',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


const styles = theme => ({
    canvasContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'center',
        },
    },
    canvas: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
    },
    colorChoice: {
        [theme.breakpoints.down('sm')]: {
            order: 3,
            marginLeft: 32,
        },
        marginRight: 32,
    },
    shapeChoice: {
        [theme.breakpoints.down('sm')]: {
            order: 2,
            marginRight: 32,
        },
        marginLeft: 32,
    },
    gameBtn: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 32,
        },
        [theme.breakpoints.down('md')]: {
            display: 'block',
            width: '100%',
            margin: '24px auto',
        },
    }
});

class Game extends React.Component {

    state = {
        canvas: {
            width: 540,
            height: 540,
        },
        code: '3 1222 2121 2233 2312 3211 3323',
        pionsBase: [],
        casesDisp: [],

        colorPion: 1,
        formPion: 1,
        pionsJoueur: [],

        //large: document.getElementById("game").offsetWidth - 30,

        game: null,
        note: 3,
        transphere: null,
        coef: null,
        rayon: null,
        cote: null,

        gameRulesModalOpen: false,
        successOpen: false,
        defeatOpen: false,
        winAlertOpen: false,
        snackbarTooDifficultOpen: false,
        changeLevelAnimationOpen: false,

        dataFetched: false,

        level: 0,
        numberOfGamesToday: 0,
        experience: 0,

        role: null,
    };

    handleClose = () => {
        this.setState({
            successOpen: false,
            defeatOpen: false,
        });
    };

    renderSnack = (type, msg, open) => {
        return (
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert
                    onClose={this.handleClose}
                    style={{width: 300}}
                    variant="filled"
                    severity={type}
                >
                    <AlertTitle>{type == 'success' ? 'Bravo !' : 'Oups ...'}</AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        )
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        const initialWidth = window.screen.availWidth;

        const canvas = {
            width: initialWidth > 600 ? 540 : initialWidth - 32,
            height: initialWidth > 600 ? 540 : initialWidth - 32,
        };

        const cote = this.props.code[0];

        const coef = canvas.width / cote;
        const rayon = coef / 4;

        if (prevProps.recordingGame && this.props.gameRecordedSuccess) {
            //console.log(this.state);
            //console.log(this.props);
            const uid = firebase.auth().currentUser.uid
            fetchUserLevel(uid)
                .then((userData) => {
                    const {level, experience} = userData;

                    const oldUserLevel = calculateLevelFromXp(this.state.experience);
                    const newUserLevel = calculateLevelFromXp(experience);

                    if (oldUserLevel !== newUserLevel) {
                        this.setState({
                            changeLevelAnimationOpen: true,
                        })
                    }

                    this.setState({
                        coef,
                        rayon,
                        cote,
                        level,
                        experience,
                        numberOfGamesToday: this.state.numberOfGamesToday + 1
                    })
                });

            this.drawPlateau(this.state.ctx, this.props.code)
        } else if (prevProps.decrementingUserLevel && (this.props.decrementedSuccessMessage || this.props.decrementedErrorMessage)) {

            const newLevel = this.state.level - 1;
            this.setState({
                coef,
                rayon,
                cote,
                level: newLevel
            });
            this.drawPlateau(this.state.ctx, this.props.code)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //console.log(this.props.recordingGame);
        //console.log(nextProps.gameRecordedSuccess);
        if (this.props.recordingGame && nextProps.gameRecordedSuccess) {
            this.setState({
                winAlertOpen: false,
                note: 3,
            })
        } else if (nextProps.recordingGame && nextProps.gameRecordedFail) {
            this.setState({
                winAlertOpen: false,
                note: 3,
            })
        }

        if (this.props.decrementingUserLevel && (nextProps.decrementedSuccessMessage || nextProps.decrementedErrorMessage)) {
            this.setState({snackbarTooDifficultOpen: true,})
        }
    }


    componentDidMount() {

        const initialWidth = window.screen.availWidth;

        const canvas = {
            width: initialWidth > 600 ? 540 : initialWidth - 48,
            height: initialWidth > 600 ? 540 : initialWidth - 48,
        };


        (async () => {
            const role = await getCustomClaimRole();
            console.log('role ', role);
            this.setState({role})
        })()

        const currentUser = firebase.auth().currentUser;

        fetchUserLevel(currentUser.uid)
            .then((userData) => {
                const {level, experience} = userData;
                const fullCode = getCode(level);
                const cote = fullCode[0];
                const coef = canvas.width / cote;
                const rayon = coef / 4;
                let code = fullCode.substr(2);

                firebase.database().ref('userGames')
                    .child(currentUser.uid)
                    .once('value')
                    .then((snap) => {
                        let numberOfGamesToday = 0
                        if (snap.val()) {
                            for (const [key, item] of Object.entries(snap.val())) {

                                const isGameToday = moment(item.date).isSame(moment(), 'day');
                                if (isGameToday) {
                                    numberOfGamesToday++;
                                }
                            }
                        }
                        const ctx = this.canvas.getContext('2d')
                        this.setState({
                            dataFetched: true,
                            code,
                            ctx,
                            cote,
                            coef,
                            rayon,
                            level,
                            experience,
                            numberOfGamesToday,
                            canvas,
                            fullCode,
                        });
                        this.drawPlateau(ctx, fullCode);
                    })

                //console.log('fullCode', fullCode)
                //console.log('code', code)
                //console.log('coté', cote)

            })
            .catch(e => console.log('error fetch user level ', e))
    }

    verif = () => {
        let test = 0;
        let testColor = [0, 0, 0, 0, 0];
        let testForm = [0, 0, 0, 0, 0];
        let pionsAll = [...this.state.pionsJoueur];
        this.state.pionsBase.forEach(e => {
            pionsAll.push({x: e.x, y: e.y, color: e.color, form: e.form});
        });
        //console.log('pionsAll', pionsAll)

        const cote = this.state.cote;
        // console.log('pionsAll', pionsAll);
        // console.log('cote ', cote);
        // console.log('this.state ', this.state);
        // console.log('Math.pow(cote, 2) ', Math.pow(cote, 2));

        if (pionsAll.length == Math.pow(cote, 2)) {
            pionsAll.forEach(e => {
                pionsAll.forEach(f => {
                    if ((e.x == f.x || e.y == f.y) && (e.color == f.color || e.form == f.form)) {
                        test++;
                    }
                });
                if (e.color == 1) {
                    testColor[0]++;
                } else if (e.color == 2) {
                    testColor[1]++;
                } else if (e.color == 3) {
                    testColor[2]++;
                } else if (e.color == 4) {
                    testColor[3]++;
                } else if (e.color == 5) {
                    testColor[4]++;
                }
                if (e.form == 1) {
                    testForm[0]++;
                } else if (e.form == 2) {
                    testForm[1]++;
                } else if (e.form == 3) {
                    testForm[2]++;
                } else if (e.form == 4) {
                    testForm[3]++;
                } else if (e.form == 5) {
                    testForm[4]++;
                }
            });
            testColor.forEach(e => {
                if (e !== cote * 1 && e !== 0) {
                    test = 100;
                }
            });
            testForm.forEach(e => {
                if (e !== cote * 1 && e !== 0) {
                    test = 100;
                }
            });
            if (test == pionsAll.length) {
                this.setState({
                    winAlertOpen: true,
                    defeatOpen: false,
                });

            } else {
                this.setState({
                    defeatOpen: true,
                    defeatMessage: 'C\'est pas encore gagné !',
                });
            }
        } else {
            this.setState({
                defeatOpen: true,
                defeatMessage: 'Grille incomplète',
            });
        }
    }

    drawPlateau(ctx, fullCode) {

        //const {casesDisp, pionsBase} = this.state;

        const casesDisp = [];
        const pionsBase = [];

        this.setState({pionsJoueur: []});
        const initialWidth = window.screen.availWidth;

        const canvas = {
            width: initialWidth > 600 ? 540 : initialWidth - 48,
            height: initialWidth > 600 ? 540 : initialWidth - 48,
        };

        ctx.beginPath();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // console.log(this.props);

        const cote = fullCode[0];
        let code = fullCode.substr(2);
        const coef = canvas.width / cote;
        const rayon = coef / 4;

        for (let i = 1; i <= cote; i++) {
            for (let j = 1; j <= cote; j++) {
                casesDisp.push({x: i, y: j});
                ctx.moveTo(0, i * coef);
                ctx.lineTo(canvas.width, i * coef);
                ctx.moveTo(i * coef, 0);
                ctx.lineTo(i * coef, canvas.height);
            }
        }
        ctx.moveTo(0, 0);
        ctx.lineTo(canvas.width, 0);
        ctx.moveTo(0, 0);
        ctx.lineTo(0, canvas.height);
        ctx.lineWidth = 3;
        ctx.strokeStyle = "white";
        ctx.stroke();
        while (code.length !== 0) {
            let index = 0;
            pionsBase.push({x: code[index], y: code[index + 1], color: code[index + 2], form: code[index + 3]});
            code = code.substr(5);
        }
        //console.log('pionsBase', pionsBase)
        code += cote;
        pionsBase.forEach(e => {
            //console.log('pion base : ', e)
            code += " " + e.x + e.y + e.color + e.form;
            if (e.form == 1) {
                this.drawCercle(e, ctx, coef, rayon);
            } else {
                this.drawPolygone(e, ctx, coef, rayon);
            }
            if (e.color == 1) {
                ctx.fillStyle = "red";
            } else if (e.color == 2) {
                ctx.fillStyle = "orange";
            } else if (e.color == 3) {
                ctx.fillStyle = "blue";
            } else if (e.color == 4) {
                ctx.fillStyle = "green";
            } else if (e.color == 5) {
                ctx.fillStyle = "purple";
            }
            ctx.fill();
            ctx.lineWidth = 5;
            ctx.strokeStyle = "black"
            ctx.stroke();
            for (let i = casesDisp.length - 1; i >= 0; i--) {
                if (e.x == casesDisp[i].x && e.y == casesDisp[i].y) {
                    casesDisp.splice(i, 1);
                }
            }
            // console.log('cases dispo :', casesDisp)
            this.setState({
                casesDisp,
                pionsBase,
            });
        });
    }

    drawPolygone(e, ctx, coef, rayon) {
        let angle = 195;
        let centreRep = {x: e.x * coef - coef / 2, y: e.y * coef - coef / 2};
        let pointActu = {x: centreRep.x + rayon, y: centreRep.y};
        pointActu = this.rotate(pointActu, centreRep, angle);
        angle = 360 / (e.form * 1 + 1);
        ctx.beginPath();
        ctx.moveTo(pointActu.x, pointActu.y);
        for (let i = 1; i <= e.form; i++) {
            pointActu = this.rotate(pointActu, centreRep, angle);
            ctx.lineTo(pointActu.x, pointActu.y);
        }
        ctx.closePath();
    }

    drawCercle(e, ctx, coef, rayon) {
        ctx.beginPath();
        ctx.arc(e.x * coef - coef / 2, e.y * coef - coef / 2, rayon, 0, Math.PI * 2);
    }

    rotate(pointActu, centreRep, angle) {
        let xM, yM, x, y;
        angle *= Math.PI / 180;
        xM = pointActu.x - centreRep.x;
        yM = pointActu.y - centreRep.y;
        x = xM * Math.cos(angle) + yM * Math.sin(angle) + centreRep.x;
        y = -xM * Math.sin(angle) + yM * Math.cos(angle) + centreRep.y;
        return ({x: Math.round(x), y: Math.round(y)});
    }

    drawPion = (f) => {
        const {
            ctx,
            coef,
            casesDisp,
            pionsJoueur,
            colorPion,
            formPion,
            rayon,
        } = this.state;

        let newPions = pionsJoueur;

        let selectedBlockX = Math.floor(f.nativeEvent.offsetX / coef) + 1;
        let selectedBlockY = Math.floor(f.nativeEvent.offsetY / coef) + 1;
        let isBlockAvailable = casesDisp.find((block) => block.x === selectedBlockX && block.y === selectedBlockY);

        if (isBlockAvailable) {
            const indexPionToRemove = newPions.findIndex((pion) => pion.x === selectedBlockX && pion.y === selectedBlockY);
            if (indexPionToRemove !== -1) {
                newPions.splice(indexPionToRemove, 1);
            }

            const newPion = {
                x: selectedBlockX,
                y: selectedBlockY,
                color: colorPion,
                form: formPion,
            };
            newPions.push(newPion);
        }
        newPions.forEach(e => {
            ctx.beginPath();
            ctx.arc(e.x * coef - coef / 2, e.y * coef - coef / 2, rayon * 1.33, 0, Math.PI * 2);
            ctx.fillStyle = 'lightgrey';
            ctx.fill();

            if (e.form == 1) {
                this.drawCercle(e, ctx, coef, rayon);
            } else {
                this.drawPolygone(e, ctx, coef, rayon);
            }
            if (e.color == 1) {
                ctx.fillStyle = "red";
            } else if (e.color == 2) {
                ctx.fillStyle = "orange";
            } else if (e.color == 3) {
                ctx.fillStyle = "blue";
            } else if (e.color == 4) {
                ctx.fillStyle = "green";
            } else if (e.color == 5) {
                ctx.fillStyle = "purple";
            }
            ctx.fill();
            ctx.lineWidth = 5;
            ctx.strokeStyle = "white"
            ctx.stroke();
        });
        this.setState({pionsJoueur: newPions})

    };

    handleChangeColor = (e) => {
        this.setState({
            colorPion: parseInt(e.target.value)
        });
    };

    handleChangeShape = (e) => {
        this.setState({
            formPion: parseInt(e.target.value)
        });
    };

    closeWinAlert = () => {
        this.props.recordUserGame(this.state.note, this.props.code);
    };

    setNote = (note) => {
        this.setState({note})
    };

    handleCloseTooDifficultSnackbar = () => {
        this.setState({snackbarTooDifficultOpen: false})
    };

    renderLevelBlock() {
        const {level} = this.state;
        return (
            <Grid item xs={12} style={{borderBottom: '1px solid darkgrey'}}>
                <Box pl={2} pr={5}>

                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <LevelStepper experience={this.state.experience}/>
                        </Grid>
                        <Grid item style={{margin: '0 auto'}}>
                            <Box pt={3}>
                                {
                                    !this.state.dataFetched ?
                                        <CircularProgress style={{color: '#04D28C'}} size={100} thickness={5}/>
                                        :
                                        <CircularLevelProgress level={level}/>

                                }
                                <h3 style={{marginTop: 8, marginLeft: 4, fontSize: 22, color: '#04D28C',}}>
                                    <b>Difficulté</b>
                                </h3>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Grid>
        );
    }

    openGameRulesModal() {
        this.setState({gameRulesModalOpen: true});
    }

    closeGameRulesModal = () => {
        this.setState({gameRulesModalOpen: false});
    }

    renderColorChoice() {
        const {classes} = this.props;

        const ColoredBox = ({color}) => (
            <Box style={{background: color, padding: '16px 32px'}}>
            </Box>
        );

        return (
            <Box pt={4} className={classes.colorChoice}>
                <FormControl component="fieldset">
                    <MyFormLabel text="Couleur"/>
                    <RadioGroup aria-label="couleur" name="color" value={this.state.colorPion}
                                onChange={this.handleChangeColor} style={{color: 'white'}}>
                        <FormControlLabel
                            value={1}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={red[800]}/>}
                            style={{margin: 0}}
                        />
                        <FormControlLabel
                            value={2}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={yellow[800]}/>}
                            style={{margin: 0}}
                        />
                        <FormControlLabel
                            value={3}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={blue[800]}/>}
                            style={{margin: 0}}
                        />
                        <FormControlLabel
                            value={4}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={green[800]}/>}
                            style={{margin: 0}}
                            disabled={this.state.cote < 4}
                        />
                        <FormControlLabel
                            value={5}
                            control={<GreenRadio/>}
                            label={<ColoredBox color={purple[800]}/>}
                            style={{margin: 0}}
                            disabled={this.state.cote < 5}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        );
    }

    renderShapeChoice() {
        const {classes} = this.props;
        return (
            <Box pt={4} className={classes.shapeChoice}>
                <FormControl component="fieldset">
                    <MyFormLabel text="Forme"/>
                    <RadioGroup aria-label="forme" name="shape" value={this.state.formPion}
                                onChange={this.handleChangeShape} style={{color: 'white'}}>
                        <FormControlLabel
                            value={1}
                            control={<GreenRadio/>}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={CircleIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={2}
                            control={<GreenRadio/>}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={TriangleIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={3}
                            control={<GreenRadio/>}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={SquareIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={4}
                            control={<GreenRadio/>}
                            disabled={this.state.cote < 4}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={PentagoneIcon} alt=""/>}
                        />
                        <FormControlLabel
                            value={5}
                            control={<GreenRadio/>}
                            disabled={this.state.cote < 5}
                            label={<img
                                style={{filter: 'invert(100%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(115%) contrast(100%)'}}
                                src={HexagoneIcon} alt=""/>}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        );
    }

    render() {

        const {
            defeatOpen,
            defeatMessage,
        } = this.state;
        const {classes} = this.props;
        // console.log(this.state.code)

        return (
            <Grid container justify="center" alignItems="center">
                <GameWinAlert
                    note={this.state.note}
                    closeWinAlert={this.closeWinAlert}
                    setNote={this.setNote}
                    open={this.state.winAlertOpen}
                />

                {
                    this.state.changeLevelAnimationOpen && <LevelUpAnimation/>
                }

                <Snackbar open={this.state.snackbarTooDifficultOpen} autoHideDuration={6000}
                          onClose={this.handleCloseTooDifficultSnackbar}>
                    <Alert onClose={this.handleCloseTooDifficultSnackbar} severity="success" variant="filled">
                        {this.props.decrementedSuccessMessage}
                    </Alert>
                </Snackbar>

                <GameRulesDialog handleClose={this.closeGameRulesModal} open={this.state.gameRulesModalOpen}/>

                <Backdrop
                    open={this.props.recordingGame || this.props.decrementingUserLevel}
                    message={this.props.recordingGame ? 'Sauvegarde de la partie ...' : 'Ajustement du niveau ...'}
                />
                {this.renderSnack('error', defeatMessage, defeatOpen)}

                {this.renderLevelBlock()}

                {
                    this.state.numberOfGamesToday >= 3 &&
                    <div>
                        <h2 style={{color: 'white'}}>Bravo ! Vous avez effectué vos 3 parties
                            quotidiennes</h2>
                        <h2 style={{color: 'white'}}>Revenez demain !</h2>
                    </div>
                }

                <div className={classes.canvasContainer}
                     style={this.state.numberOfGamesToday < 3 ? {} : {filter: 'blur(30px)'}}>
                    {this.renderColorChoice()}
                    <Box pt={4} pb={5} pl={2} className={classes.canvas}>
                        <canvas
                            ref={ref => this.canvas = ref}
                            onClick={this.state.numberOfGamesToday < 3 ? this.drawPion : null}
                            id="canvas"
                            width={this.state.canvas.width}
                            height={this.state.canvas.height}
                            style={{
                                background: 'lightgrey'
                            }}
                        >
                        </canvas>
                    </Box>
                    {this.renderShapeChoice()}
                </div>
                <Grid container justify="center"
                      style={this.state.numberOfGamesToday < 3 ? {} : {filter: 'blur(30px)'}}>
                    <Grid item>
                        <Box py={3}>
                            <Button
                                disabled={this.state.numberOfGamesToday >= 3}
                                variant="contained"
                                style={{color: 'white', background: '#04D28C'}}
                                onClick={this.verif}
                            >
                                Verifier ma grille
                            </Button>
                            <Button
                                className={classes.gameBtn}
                                variant="contained"
                                color="secondary"
                                disabled={this.state.level === 0 || this.state.numberOfGamesToday >= 3}
                                onClick={() => this.props.decrementUserLevel()}
                            >
                                Trop difficile ?
                            </Button>
                            <Button
                                disabled={this.state.numberOfGamesToday >= 3}
                                className={classes.gameBtn}
                                variant="contained"
                                color="primary"
                                onClick={() => this.openGameRulesModal()}
                            >
                                Voir les règles
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = ({userGames, auth}) => {
    const {
        recordingGame,
        gameRecordedSuccess,
        gameRecordedFail,
        decrementingUserLevel,
        decrementedSuccessMessage,
        decrementedErrorMessage,
        code,
    } = userGames;
    const {user} = auth;
    return {
        recordingGame,
        gameRecordedSuccess,
        gameRecordedFail,
        user,
        decrementingUserLevel,
        decrementedSuccessMessage,
        decrementedErrorMessage,
        code,
    };
};

const mapDispatchToProps = {
    recordUserGame,
    decrementUserLevel,
};

const WithStylesGame = withStyles(styles)(Game);
export default connect(mapStateToProps, mapDispatchToProps)(WithStylesGame);
