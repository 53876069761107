import firebase from 'firebase';

function getLevels() {
   const allLevel = [
       {cote: 3, pawnsGiven: 6, joker: 1},
       {cote: 4, pawnsGiven: 12, joker: 1},
       {cote: 3, pawnsGiven: 5, joker: 1},
       {cote: 4, pawnsGiven: 11, joker: 1},
       {cote: 3, pawnsGiven: 4, joker: 1},
       {cote: 4, pawnsGiven: 10, joker: 1},
       {cote: 3, pawnsGiven: 3, joker: 2},
       {cote: 4, pawnsGiven: 9, joker: 2},
       {cote: 5, pawnsGiven: 20, joker: 1},
       {cote: 5, pawnsGiven: 19, joker: 1},
       {cote: 5, pawnsGiven: 18, joker: 1},
       {cote: 4, pawnsGiven: 8, joker: 2},
       {cote: 5, pawnsGiven: 17, joker: 2},
       {cote: 5, pawnsGiven: 16, joker: 2},
       {cote: 5, pawnsGiven: 15, joker: 2},
       {cote: 4, pawnsGiven: 7, joker: 2},
       {cote: 5, pawnsGiven: 14, joker: 3},
       {cote: 5, pawnsGiven: 13, joker: 3},
       {cote: 5, pawnsGiven: 12, joker: 3},
       {cote: 4, pawnsGiven: 6, joker: 3},
       {cote: 5, pawnsGiven: 11, joker: 4},
       {cote: 5, pawnsGiven: 10, joker: 4},
       {cote: 5, pawnsGiven: 9, joker: 4},
       {cote: 4, pawnsGiven: 5, joker: 3},
       {cote: 5, pawnsGiven: 8, joker: 5},
       {cote: 5, pawnsGiven: 7, joker: 5},
       {cote: 5, pawnsGiven: 6, joker: 5},
       {cote: 4, pawnsGiven: 4, joker: 3},
       {cote: 5, pawnsGiven: 5, joker: 6}
   ]
    return allLevel;
}

export async function getCustomClaimRole() {
    await firebase.auth().currentUser.getIdToken(true);
    const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
}

export const getCode = (userLevel) => {
    let code;
    let casesDisp = [];
    let pionsDisp = [];
    let pionsJoues = [];
    let transphere;
    let allLevel = getLevels();
    const {cote, pawnsGiven} = allLevel[userLevel];

    function drawPlateau() {
        code = "";
        casesDisp = [];
        pionsDisp = [];
        pionsJoues = [];
        for (let i = 1; i <= cote; i++) {
            for (let j = 1; j <= cote; j++) {
                casesDisp.push({x: i, y: j});
                pionsDisp.push({color: i, form: j});
            }
        }
        for (let i = 1; i <= Math.pow(cote, 2) * 5; i++) {
            transphere = pionsDisp.splice(Math.floor(Math.random() * Math.pow(cote, 2)), 1);
            pionsDisp.push(transphere[0]);
        }
        pionsJoues.push({x: casesDisp[0].x, y: casesDisp[0].y, color: pionsDisp[0].color, form: pionsDisp[0].form});
        casesDisp.splice(0, 1);
        pionsDisp.splice(0, 1);
        for (let i = pionsDisp.length - 1; i >= 0; i--) {
            placePawn();
        }
        for (let i = 1; i <= Math.pow(cote, 2) - pawnsGiven; i++) {
            pionsJoues.splice(Math.floor(Math.random() * pionsJoues.length), 1);
        }
        if (pionsDisp.length !== 0) {
            drawPlateau();
        }
        code += cote;
        pionsJoues.forEach(e => {
            code += " " + e.x + e.y + e.color + e.form;
        });

    }

    function placePawn() {
        for (let i = pionsDisp.length - 1; i >= 0; i--) {
            let pionWithSameShapeOrColorInLine = pionsJoues.find((e) => (casesDisp[0].x == e.x || casesDisp[0].y == e.y) && (pionsDisp[i].color == e.color || pionsDisp[i].form == e.form))

            if (!pionWithSameShapeOrColorInLine) {
                const newPion = {
                    x: casesDisp[0].x,
                    y: casesDisp[0].y,
                    color: pionsDisp[i].color,
                    form: pionsDisp[i].form,
                };
                pionsJoues.push(newPion);
                casesDisp.splice(0, 1);
                pionsDisp.splice(i, 1);
                break;
            }
        }
    }

    drawPlateau();
    code = code.split('');
    while (code.length > pawnsGiven * (4 + 1) + 1) {
        code.splice(0, 1);
    }
    let vraiCode = '';
    code.forEach(e => {
        vraiCode += e;
    });
    return vraiCode;
};

export const getExperienceForLevel = (level) => {
    const experienceNeeded  = 25 * level * level - 25 * level;
    return experienceNeeded;
}
export const calculateLevelFromXp = (xp) => {
    const nb = (Math.sqrt(625 + 100 * xp) - 25) / 50;
    return Math.floor(nb);
};
