import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import QuestionMarkIcon from '@material-ui/icons/Help';
import HourglassIcon from '@material-ui/icons/HourglassFull';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import ChartIcon from '@material-ui/icons/Poll';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";
import MobileStepper from '@material-ui/core/MobileStepper';

import {calculateLevelFromXp, getExperienceForLevel} from "../services";
import Hidden from "@material-ui/core/Hidden";
import ExperienceStepper from "./ExperienceStepper";

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#fff',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#fff',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
        left: 'calc(-85% + 20px)',
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(3, 255, 169) 0%, rgb(2, 199, 132) 50%, rgb(2, 120, 80 ) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const MyTrophyIcon = () => (
        <TrophyIcon style={{color: '#FFD700'}}/>
    );

    const icons = {
        1: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        2: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        3: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        4: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        5: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        6: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        7: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        8: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        9: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        10: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
        11: completed ? <MyTrophyIcon/> : (active ? <HourglassIcon/> : <QuestionMarkIcon/>),
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    stepper: {
        background: '#313954',
        color: '#fff',
    },
    label: {
        color: '#fff',
        whiteSpace: 'nowrap',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: 'white',
    },
    title: {
        fontSize: 26,
        fontWeight: 900,
        color: 'rgb(4, 210, 140)',
    },
    level: {
        fontSize: 18,
        margin: 0,
        fontWeight: 'bold',
    },
    levelIcon: {
        color: 'rgb(4, 210, 140)',
        fontSize: 32,
    },
    levelSpan: {
        color: 'rgb(4, 210, 140)',
    },
    mobileRoot: {
        width: 300,
        flex: 1,
    },
    mobileProgress: {
        width: '100% !important',
        height: 10,
    },
    levelName: {
        color: 'white',
        fontWeight: 800
    }
}));

function getSteps() {
    return [
        'Novice',
        'Débutant',
        'Apprenti',
        'Amateur',
        'Intermédiaire',
        'Confirmé',
        'Adepte',
        'Connaisseur',
        'Expert',
        'Maître',
        'Grand maître',
    ];
}

function getStepContent(step, classes, xp) {

    const experienceNextLevel = getExperienceForLevel(step + 2 );


    const StepContent = ({stepNumber}) => (
        <Grid container alignItems="center" spacing={2}>
            <Grid item style={{display: 'flex'}}>
                <ChartIcon className={classes.levelIcon}/>
            </Grid>
            <Grid item style={{flex: 1}}>
                <p className={classes.level}>
                    <span className={classes.levelSpan}>Niveau {stepNumber + 1}&nbsp;</span>
                    / 11
                </p>
            </Grid>
            <Grid item xs={12} sm={6}>

                <h3 className={classes.levelSpan}>Prochain niveau</h3>
                <ExperienceStepper step={step} experience={xp}/>

            </Grid>
        </Grid>
    );

    return <StepContent stepNumber={step}/>;
}

const ProgressMobileStepper = ({step}) => {
    const classes = useStyles();
    return (
        <MobileStepper
            variant="progress"
            steps={12}
            position="static"
            activeStep={step}
            classes={{root: classes.mobileRoot, progress: classes.mobileProgress}}
        />
    );
}



export default function LevelStepper(props) {
    const classes = useStyles();
    const levelExperience = calculateLevelFromXp(props.experience);
    const [activeStep, setActiveStep] = React.useState(levelExperience);
    const steps = getSteps();

    React.useEffect(() => {
        setActiveStep(levelExperience)
    });

    return (
        <React.Fragment>
            <Hidden mdUp>
                <Box>
                    <h4 className={classes.title}>Votre niveau de progression</h4>
                </Box>
                <ProgressMobileStepper step={activeStep}/>
                <p className={classes.levelName}>{steps[activeStep]}</p>
                <Box my={3} >
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                        </div>
                    ) : (
                        <div className={classes.instructions}>
                            {getStepContent(activeStep, classes, props.experience)}
                        </div>
                    )}
                </Box>
            </Hidden>
            <Hidden smDown>
                <div className={classes.root}>

                    <Box px={5}>
                        <h4 className={classes.title}>Votre niveau de progression</h4>
                    </Box>
                    <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep}
                             connector={<ColorlibConnector/>}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel className={classes.label}
                                           StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        <Box px={5} mb={2}>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                </div>
                            ) : (
                                <div className={classes.instructions}>
                                    {getStepContent(activeStep, classes, props.experience)}
                                </div>
                            )}
                        </Box>
                    </div>
                </div>
            </Hidden>
        </React.Fragment>
    );
}
