import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';
import {Route, Switch} from "react-router";

// HOCS
import requireAuth from "./hocs/requireAuth";
import requireAnon from "./hocs/requireAnon";
import requireAdmin from "./hocs/requireAdmin";

// REDUX & REDUX PERSISTS
import {Provider} from 'react-redux'
import {persistStore} from "redux-persist";
import {PersistGate} from 'redux-persist/integration/react'

// REDUX STORE
import configureStore, {history} from './store';
import {ConnectedRouter} from "connected-react-router";

// STYLES
import './index.css'
import './App.css'

// PAGES
import SignIn from "./pages/SingIn";
import App from './pages/App';
import NoMatchPage from "./pages/NoMatchPage";
import PasswordReset from "./pages/PasswordReset";
import Subscription from "./pages/Subscription";
import AdminPanel from "./pages/AdminPanel";
import UserDetails from "./pages/UserDetails";
import LegalNotice from "./pages/LegalNotice";
import CGV from "./pages/CGV";
import GameTest from "./pages/GameTestV2";
import Test from "./pages/Test";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// CONFIGURATION
const store = configureStore();
const persistor = persistStore(store);

const firebaseConfig = {
    apiKey: "AIzaSyCYLpR6GpX7O-WPhufkFDSfiXklI-P8nrA",
    authDomain: "adn2-66c7d.firebaseapp.com",
    databaseURL: "https://adn2-66c7d.firebaseio.com",
    projectId: "adn2-66c7d",
    storageBucket: "adn2-66c7d.appspot.com",
    messagingSenderId: "85887628556",
    appId: "1:85887628556:web:73e710f9209ba58fb2c529",
    measurementId: "G-6SK2KSE5F5"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
// END CONFIGURATION

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route exact path="/register" component={requireAnon(GameTest)}/>
                        <Route exact path="/test" component={requireAuth(Test)}/>
                        <Route exact path="/app" component={requireAuth(App)}/>
                        <Route exact path="/" component={requireAnon(SignIn)}/>
                        <Route exact path="/password-reset" component={requireAnon(PasswordReset)}/>
                        <Route exact path="/subscription" component={requireAuth(Subscription)}/>
                        <Route exact path="/mentions-legales" component={LegalNotice}/>
                        <Route exact path="/conditons-generales-vente" component={CGV}/>
                        <Route exact path="/politique-confidentialite" component={PrivacyPolicy}/>
                        <Route exact path="/admin" component={requireAdmin(AdminPanel)}/>
                        <Route exact path="/admin/users/:userId" component={requireAdmin(UserDetails)}/>
                        <Route component={NoMatchPage}/>
                    </Switch>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
