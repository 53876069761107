import React from 'react';
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Layout from "../components/Layout";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(2),
    },
}));

function CGV() {

    const classes = useStyles();

    return (
        <Layout>
            <Container maxWidth="md" className={classes.container}>
                <h1>Conditions Générales de Vente</h1>


                <h2>
                    ARTICLE 1 - PRIX
                </h2>

                Les prix de nos produits sont indiqués en euros TTC. Toutes les commandes quelle que soit leur origine
                sont payables en euros.

                La Sasu "Agitateurs De Neurones (A-D-N)" se réserve le droit de modifier ses prix à tout moment mais les
                produits seront facturés sur la base des tarifs en vigueur au moment de l'enregistrement des commandes
                sous réserve de disponibilité. Les produits demeurent la propriété de Sasu "Agitateurs De Neurones
                (A-D-N)" jusqu'au complet paiement du prix.

                <h2>
                    ARTICLE 2 - COMMANDE
                </h2>

                Sur Internet : http://www.agitateur-de-neurones.net
                Vous pouvez également commander par téléphone du lundi au vendredi de 8h30 à 12h et de 13h30 à 17h.

                <h2>
                    ARTICLE 3 - VALIDATION
                </h2>

                Quand vous cliquez sur le bouton Valider la commande après le processus de commande, vous déclarez
                accepter celle-ci ainsi que l'intégralité des présentes Conditions Générales de Vente pleinement et sans
                réserve. Les données enregistrées par http://www.agitateur-de-neurones.net constituent la preuve de
                l'ensemble des transactions passées par http://www.agitateur-de-neurones.net et ses clients. Les données
                enregistrées par le système de paiement constituent la preuve des transactions financières. La Sasu
                "Agitateurs De Neurones (A-D-N)" confirme l'acceptation de la commande au client à l'adresse mail que
                celui-ci aura communiqué. La Sasu "Agitateurs De Neurones (A-D-N)" se réserve le droit d'annuler toute
                commande d'un client avec lequel existerait un litige relatif au paiement d'une commande antérieure.

                <h2>
                    ARTICLE 4 - DISPONIBILITE
                </h2>

                Nos offres de produits sont proposées dans la limite des stocks disponibles.

                Nos offres sont valables sous réserve de disponibilité chez le fournisseur.
                En cas d'indisponibilité de produit après passation de votre commande nous vous en informerons : par
                mail ou par courrier dans les meilleurs délais.

                Vous pourrez alors demander l'annulation ou l'échange de votre commande.


                <h2>
                    ARTICLE 5 - LIVRAISON
                </h2>

                Les produits sont livrés à l'adresse de livraison que vous avez indiquée au cours du processus de
                commande, et sont adressés par livraison, soit un délai de 3 à 5 jours pour la France métropolitaine.
                Les délais indiqués sont des délais moyens et correspondent aux délais de traitement et à la livraison à
                destination de la France métropolitaine et Monaco.


                <h2>
                    ARTICLE 6 - PAIEMENT
                </h2>

                Le règlement s'effectue par carte bancaire, chèque ou virement lors de votre commande.


                <h2>
                    ARTICLE 7 : SECURISATION
                </h2>

                Notre site fait l'objet d'un système de sécurisation de paiement par carte bancaire avec notre
                partenaire Shine et son système de paiement en ligne.


                <h2>
                    ARTICLE 8 - SATISFAIT OU REMBOURSE
                </h2>

                Vous disposez d'un délai de 7 jours à compter de la date de votre commande pour l'annuler et être
                remboursé.

                Les remboursements seront effectués dans un délai inférieur ou égal à 30 jours après la réception de
                votre demande et après retour de l'objet à vos frais. Le remboursement s'effectuera au choix de la Sasu
                "Agitateurs De Neurones (A-D-N)" par crédit sur votre compte bancaire ou par chèque bancaire adressé au
                nom du client ayant passé la commande et à l'adresse de facturation.

                <h2>
                    ARTICLE 9 - SERVICE CLIENTELE
                </h2>

                Pour toute information ou question, notre service Clients est à votre disposition :

                Tél: 0 771 771 111 du lundi au vendredi de 9h00 à 12h00 et de 14h00 à 17h00

                Courriel : contact@agitateur-de-neurones.net

                Adresse : Sasu "Agitateurs De Neurones (A-D-N)"
                120 Porte de la Bastide 40300 Hastingues

                <h2>
                    ARTICLE 10 - PROPRIETE INTELLECTUELLE
                </h2>

                Tous les textes, illustrations et images reproduits sur le site sont réservés au titre du droit d'auteur
                ainsi qu'au titre de la propriété intellectuelle et pour le monde entier. A ce titre et conformément aux
                dispositions du Code de la Propriété Intellectuelle, seule l'utilisation pour un usage privé sous
                réserve de dispositions différentes voire plus restrictives du code de la propriété intellectuelle.
                Toute autre utilisation est constitutive de contrefaçon et sanctionnée au titre de la Propriété
                Intellectuelle sauf autorisation préalable de la Sasu "Agitateurs De Neurones (A-D-N)". Toute
                reproduction totale ou partielle du catalogue de la Sasu "Agitateurs De Neurones (A-D-N)" est
                strictement interdite.

                <h2>
                    ARTICLE 11 - RESPONSABILITE
                </h2>

                Les produits proposées sont conformes à la législation française en vigueur. La responsabilité de la
                Sasu "Agitateurs De Neurones (A-D-N)" ne saurait être engagée en cas de non respect de la législation.
                Les photographies présentées ne sont pas contractuelles. En conséquence, la responsabilité de la Sasu
                "Agitateurs De Neurones (A-D-N)" ne saurait être engagée en cas d'erreur dans l'une de ces photographies
                ou l'un de ces textes. La Sasu "Agitateurs De Neurones (A-D-N)" ne saurait être tenu pour responsable de
                l'inexécution du contrat conclu en cas de rupture de stock ou indisponibilité du produit, de force
                majeure, de perturbation ou grève totale ou partielle notamment des services postaux et moyens de
                transport et/ou communications, inondation, incendie. La Sasu "Agitateurs De Neurones (A-D-N)"
                n'encourra aucune responsabilité pour tous dommages indirects du fait des présentes, perte
                d'exploitation, perte de profit, perte de chance, dommages ou frais.

                <h2>
                    ARTICLE 12 - DROIT APPLICABLE - LITIGES
                </h2>

                Le présent contrat est soumis à la loi française. La langue du présent contrat est la langue française.
                En cas de litige les tribunaux français seront seuls compétents.

                <h2>
                    ARTICLE 13 - INFORMATIONS NOMINATIVES
                </h2>

                Les informations vous concernant que vous nous communiquez sont indispensables au traitement de votre
                commande et ne seront jamais communiquées à des tiers. En application de la loi informatique et libertés
                du 6 janvier 1978, vous disposez d'un droit d'accès et de rectification de ces informations.


            </Container>
        </Layout>
    );
}

export default CGV;
