import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Règles du jeu
            </DialogTitle>
            <DialogContent dividers>

                <Typography gutterBottom>
                    Vous allez stimuler votre cerveau en jouant avec des grilles de difficulté croissante.
                </Typography>

                <Typography gutterBottom>
                    Votre objectif est de compléter chaque grille proposée en remplissant chaque ligne et rangée sans "doublons", c.a.d sans trouver deux cases avec la même forme ou la même couleur ! Vous ne pouvez pas utiliser deux fois la même combinaison "forme/couleur" par grille.
                </Typography>
                <Typography gutterBottom>
                    Avec une grille de 3X3 cases vous ferez un choix parmi les 3 premières Formes et
                    3 premières Couleurs de part et d'autre de la grille.
                    Avec une grille de 4X4 cases pour laquelle vous utiliserez les 4 premières Formes et Couleurs .
                    Et avec une grille de 5X5 cases vous choisirez parmi les 5 Formes et 5 Couleurs proposées.

                </Typography>
                <Typography gutterBottom>
                    Ensuite vous cliquez votre sélection directement dans la case à remplir.
                    Et ainsi de suite jusqu'à compléter la grille de jeu.
                </Typography>
                <Typography gutterBottom>
                    Si vous voulez rattraper une erreur c'est très simple, vous sélectionnez une nouvelle Forme
                    et/ou une nouvelle Couleur que vous posez directement sur la case "d'erreur" en cliquant dessus !
                </Typography>

                {
                    !props.testPage &&
                    <Typography gutterBottom>
                        Une fois la grille complète et vérifiée, vous serez invité à la noter de 1 à 5, de facile à
                        difficile,
                        et le "générateur de grille" vous en proposera une autre adaptée à votre expérience de jeu.
                    </Typography>
                }

                <Typography gutterBottom>
                    Prenez plaisir à ce formidable jeu d'équilibrage des hémisphères Gauche et Droit de votre cerveau !
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={props.handleClose} color="secondary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
