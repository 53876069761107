import React from 'react';
import Layout from "../components/Layout";
import GameVersionTest from "../components/GameVersionTest";
import Box from "@material-ui/core/Box";

class Test extends React.Component {
    render() {
        return (
            <Layout isAdmin={false}>
                <Box style={{flex: 1, background: '#313954'}}>
                    <Box p={3}>
                        <h1 style={{color :'white', margin: 0}}>Jeu test - Agitateur De Neurones</h1>
                    </Box>
                    <GameVersionTest/>
                </Box>
            </Layout>
        )
    }
}

export default Test;
