import React, {Component} from 'react';
import firebase from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import {getCustomClaimRole} from '../services';


const style = {
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
    },
    refresh: {
        display: 'inline-block',
        position: 'relative',
        margin: '0 auto',
    },
    sentence: {
        color: 'darkgrey',
        fontSize: 13,
        marginTop: 24,
    }
};


/**
 * @param  {} ComposedComponent
 */
const isUserLoggedIn = (ComposedComponent) => {

    class Authentication extends Component {
        state = {
            loading: true,
            isAdmin: false,
        };

        b64DecodeUnicode = (str) => {
            return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }
            ).join(''));
        };

        componentDidMount() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    console.log('user.emailVerified ',user.emailVerified)
                    console.log('user ',user)
                    firebase.auth().currentUser.getIdToken(false)
                        .then((idToken) => {
                            const payload = JSON.parse(this.b64DecodeUnicode(idToken.split('.')[1]));
                            if (payload.admin) {
                                this.setState({
                                    isAdmin: true,
                                    loading: false,
                                });
                            }else {

                                firebase.database().ref('/users').child(user.uid)
                                    .once('value')
                                    .then((snap) => {
                                        const data = snap.val();

                                        console.log('data.fullyRegistered ',data.fullyRegistered)
                                        this.setState({
                                            loading: false
                                        });
                                        if(!data.fullyRegistered){
                                            if( user.emailVerified){
                                                if(data.testFinished){
                                                    this.props.history.push('/register');
                                                }else{
                                                    this.props.history.push('/test');
                                                }
                                            }else{
                                                this.props.history.push('/');
                                            }
                                        }else{
                                            //TODO DISPATCH /app
                                            //this.props.history.push('/app');

                                            const creationTime = moment(user.metadata.creationTime);
                                            const tenDaysAgo = moment().subtract(7, 'days');


                                            (async () => {
                                                const role = await getCustomClaimRole();
                                                console.log('role ',role);

                                                // FREE TRIAL 7 DAYS
                                                if(creationTime.isBefore(tenDaysAgo) && role !== 'premium'){
                                                    this.props.history.push('/subscription');
                                                }
                                                this.setState({
                                                    loading: false
                                                });
                                            })()
                                        }



                                    })
                            }
                        });
                } else {
                    this.props.history.push('/');
                    this.setState({
                        loading: false
                    });
                }
            });
        }

        render() {

            if (this.state.loading) {
                return (
                    <div style={style.container}>
                        <CircularProgress/>
                        <p style={style.sentence}>Patientez, nous vérifions vos droits d'accès à la page...</p>
                    </div>
                )
            }
            return (
                <ComposedComponent isAdmin={this.state.isAdmin} {...this.props} />
            );
        }
    }

    return Authentication;
};

export default isUserLoggedIn;
