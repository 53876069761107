import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
};

export const history = createBrowserHistory();

const historyReducer =  createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, historyReducer)

export default function configureStore(preloadedState) {
    const store = createStore(
        persistedReducer, // root reducer with router state
        preloadedState,
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk,
                // ... other middlewares ...
            ),
        ),
    )

    return store
}
