import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import {connect} from "react-redux";
import moment from "moment";
import FreeTrialStepper from "./FreeTrialStepper";

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#dedede',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    subTitle: {
        fontSize: 24,
        fontWeight: 800,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: 26,
        fontWeight: 900,
        color: 'rgb(4, 210, 140)',
        marginBottom: 8
    },
}));

function FreeTrialLeftDays(props) {
    const classes = useStyles();
    const correctTimeStamp = props.user.createdAt ? props.user.createdAt.substring(0, 10) : props.user.metadata.a.substring(0, 10);
    const date = new Date(correctTimeStamp * 1000);
    const userRegistrationDate = moment(date);
    const now = moment();
    const nbDaysFromRegistration = now.diff(userRegistrationDate, 'days')
    const nbDaysLeft = 7 - nbDaysFromRegistration;

    console.log('nbDaysFromRegistration ', nbDaysFromRegistration)
    console.log('nbDaysLeft ', nbDaysLeft)
    console.log(props);

    const daysWord = nbDaysLeft >= 1 ? 'jours' : 'jour';
    return (
        <div className={classes.root}>
            <Box px={4}>
                <h4 className={classes.title}>Votre essai gratuit</h4>
            </Box>
            <Box pb={4} px={4} className={classes.container}>
                <p className={classes.subTitle}>Il vous reste {nbDaysLeft < 0 ? 0 : nbDaysLeft} {daysWord} d'essai
                    gratuit</p>
                <FreeTrialStepper step={nbDaysFromRegistration}/>
            </Box>
        </div>
    );
}

const mstp = ({auth}) => {
    const {user} = auth;
    return {user};
};

export default connect(mstp)(FreeTrialLeftDays);
