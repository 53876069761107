import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import SeeIcon from '@material-ui/icons/Visibility';
import {withRouter} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 450,
        backgroundColor: '#f5f5f5',
    },
}));

function UsersList(props) {
    const classes = useStyles();
    const {data} = props;
    return (
        <List className={classes.root}>
            {data.map((user, i) => {
                const labelId = `user-${i}`;
                return (
                    <ListItem key={i} button divider onClick={() => props.history.push(`/admin/users/${user.id}`)}>
                        <ListItemText
                            id={labelId}
                            primary={`Utilisateur id: ${user.id}`}
                            secondary={user.email}
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="see" onClick={() => props.history.push(`/admin/users/${user.id}`)}>
                                <SeeIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default withRouter(UsersList);
