import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import AuthReducer from "./AuthReducer";
import UserGamesReducer from "./UserGamesReducer";
import AdminReducer from "./AdminReducer";
import PaymentReducer from "./PaymentReducer";

const appReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    admin: AdminReducer,
    userGames: UserGamesReducer,
    payment: PaymentReducer,
});

export default appReducer;
