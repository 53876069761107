import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import GameRating from "./GameRating";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GameWinAlert(props) {

    const handleClose = () => {
        props.closeWinAlert();
    };

    return (
        <div>

            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent style={{minWidth: 320}}>
                    <Alert
                        variant="filled"
                        severity="success"
                    >
                        Bravo vous avez réussi !
                    </Alert>

                    <Box mt={4} px={1}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <b>
                                Quelle était la difficulté de cette grille sur une echelle de 1 à 5?
                            </b>
                        </DialogContentText>
                    </Box>
                    <Box mt={5}>
                        <GameRating
                            note={props.note}
                            setNote={props.setNote}
                            open={props.open}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="primary">
                        Valider ma note
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
