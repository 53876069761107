import React from 'react';
import {makeStyles} from "@material-ui/core";
import Layout from "../components/Layout";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {subscribe} from "../actions";
import Pricing from "../components/Pricing";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
    },
    alert: {
        width: '100%'
    }
}));

function Subscription(props) {

    const classes = useStyles();
    return (
        <Layout isAdmin={props.isAdmin}>
            <div className={classes.container}>
                <Alert
                    variant="filled"
                    severity="warning"
                    className={classes.alert}
                >
                    <AlertTitle>Votre essai gratuit de 7 jours est terminé... :(</AlertTitle>
                    Passez à la formule par abonnement pour jouer de manière illimitée !
                </Alert>
                <Container maxWidth="lg" className={classes.container}>


                    <Pricing subscribe={props.subscribe} checkoutLoading={props.checkoutLoading}/>

                </Container>
            </div>
        </Layout>
    );
}


const mapStateToProps = ({payment}) => {
    const {checkoutLoading} = payment;
    return {checkoutLoading};
}

export default connect(mapStateToProps, {subscribe})(Subscription);

