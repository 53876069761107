import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 9999,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default function SimpleBackdrop(props) {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            {props.noLoader ? null : <CircularProgress color="inherit"/>}
            <Box mt={2}>
                {
                    props.message ?
                        <p style={{color: 'white'}}>{props.message}</p>
                        :
                        props.content
                }
            </Box>
        </Backdrop>
    );
}
