import React from 'react';
import Layout from "../components/Layout";
import {withStyles} from '@material-ui/core/styles';
import {fetchUsers} from "../actions";
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import UsersList from "../components/UsersList";
import Box from "@material-ui/core/Box";

const styles = {};

class AdminPanel extends React.Component {

    componentDidMount() {
        this.props.fetchUsers();
    }

    render() {
        const {classes, users} = this.props;

        console.log(this.props);
        return (
            <Layout isAdmin={true}>
                <Box px={4} py={1} style={{flex: 1}}>
                    <h1>Admin panel</h1>
                    <h3>Liste des utilisateurs</h3>
                    {
                        this.props.usersFetching ? <CircularProgress/> :
                            <UsersList data={users}/>
                    }
                </Box>

            </Layout>
        );
    }

}

const mapStateToProps = ({admin}) => {
    const {users, usersFetching, usersFetchingError} = admin;
    return {users, usersFetching, usersFetchingError};
};

const mapDispatchToProps = {
    fetchUsers
};

const WithStylesAdminPanel = withStyles(styles)(AdminPanel);
export default connect(mapStateToProps, mapDispatchToProps)(WithStylesAdminPanel);
