import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const labels = {
    1: 'Très facile',
    2: 'Facile',
    3: 'Moyen',
    4: 'Difficile',
    5: 'Très difficile',
};

const useStyles = makeStyles({
    root: {
        width: 200,
        display: 'flex',
        alignItems: 'center',
    },
});

export default function HoverRating(props) {
    const [value, setValue] = React.useState(3);
    const [hover, setHover] = React.useState(-1);
    const classes = useStyles();

    React.useEffect(() => {
        console.log({open: props.open})
        if(!props.open){
            setValue(3);
        }
    }, [props.open])

    return (
        <div className={classes.root}>
            <Rating
                size="large"
                name="hover-feedback"
                value={props.note}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    props.setNote(newValue);
                }}
                onChangeActive={(event, newHover) => {
                    console.log({newHover})
                    setHover(newHover);
                }}
            />
            {value !== null && <Box ml={2} style={{whiteSpace: 'nowrap'}}>{labels[hover !== -1 ? hover : value]}</Box>}
        </div>
    );
}
