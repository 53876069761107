import {
    RECORD_USER_GAME,
    RECORD_USER_GAME_SUCCESS,
    RECORD_USER_GAME_FAIL,
    DECREMENT_USER_LEVEL,
    DECREMENT_USER_LEVEL_FAIL,
    DECREMENT_USER_LEVEL_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
    recordingGame: false,
    gameRecordedSuccess: false,
    gameRecordedFail: false,

    decrementingUserLevel: false,
    decrementedSuccessMessage: '',
    decrementedErrorMessage: '',

    code: '3 1222 2121 2233 2312 3211 3323',
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RECORD_USER_GAME:
            return {...state, recordingGame: true, gameRecordedSuccess: false, gameRecordedFail: false};
        case RECORD_USER_GAME_SUCCESS:
            return {
                ...state,
                recordingGame: false,
                gameRecordedSuccess: true,
                gameRecordedFail: false,
                code: action.payload,
            };
        case RECORD_USER_GAME_FAIL:
            return {...state, recordingGame: false, gameRecordedFail: true, gameRecordedSuccess: false};

        case DECREMENT_USER_LEVEL:
            return {
                ...state,
                decrementingUserLevel: true,
                decrementedErrorMessage: '',
                decrementedSuccessMessage: '',
            };
        case DECREMENT_USER_LEVEL_FAIL:
            return {
                ...state,
                decrementingUserLevel: false,
                decrementedErrorMessage: action.payload,
                decrementedSuccessMessage: '',
            };
        case DECREMENT_USER_LEVEL_SUCCESS:
            return {
                ...state,
                decrementingUserLevel: false,
                decrementedErrorMessage: '',
                decrementedSuccessMessage: 'Nous allons vous proposer une grille plus facile',
                code: action.payload,
            };
        default:
            return { ...state};
    }
}
