import React, {Component} from 'react';
import firebase from 'firebase';
import CircularProgress from "@material-ui/core/CircularProgress";

const style = {
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
    },
    refresh: {
        display: 'inline-block',
        position: 'relative',
        margin: '0 auto',
    },
    sentence: {
        color: 'darkgrey',
        fontSize: 13,
        marginTop: 24,
    }
};

/**
 * @param  {} ComposedComponent
 */
const isUserLoggedIn = (ComposedComponent) => {

    class Authentication extends Component {
        state = {
            loading: true,
        };

        componentDidMount() {


            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    firebase.database().ref('/users').child(user.uid)
                        .once('value')
                        .then((snap) => {
                            const data = snap.val();
                            user.reload().then(() => {
                                if (!data.fullyRegistered) {
                                    if (user.emailVerified) {
                                        this.props.history.push('/test');
                                    } else {
                                        this.props.history.push('/register');
                                    }
                                } else {
                                    this.props.history.push('/app');
                                }
                                this.setState({
                                    loading: false
                                });
                            })


                        })
                } else {
                    this.setState({
                        loading: false
                    });
                }
            });
        }

        render() {

            if (this.state.loading) {
                return (
                    <div style={style.container}>
                        <CircularProgress/>
                        <p style={style.sentence}>
                            Chargement ...
                        </p>
                    </div>
                )
            }
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }

    return Authentication;
};

export default isUserLoggedIn;
