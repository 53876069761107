import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import {red} from '@material-ui/core/colors';
import Badge from "@material-ui/core/Badge";
import LevelIcon from '@material-ui/icons/Poll';
import GameIcon from '@material-ui/icons/VideogameAsset';
import MailIcon from '@material-ui/icons/Mail';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
    },
    avatar: {
        backgroundColor: red[500],
    },
    label: {
        marginLeft: 24,
        fontSize: 14,
    },
    section: {
        display: 'flex',
        alignItems:'center',
    }
}));


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
};

export default function AdminUserCard(props) {
    const classes = useStyles();

    const {user} = props;
    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        {user.pseudo && user.pseudo.charAt(0).toUpperCase()}
                    </Avatar>
                }
                title={capitalize(user.pseudo)}
                subheader={`Inscrit le : ${user.createdAt}`}
            />
            <CardContent>

                <Box mt={3} mb={3} className={classes.section}>
                    <Badge badgeContent={user.level.toString()} color="primary">
                        <LevelIcon/>
                    </Badge>
                    <span className={classes.label}>Niveau utilisateur</span>
                </Box>
                <Box mb={3} className={classes.section}>
                    <Badge badgeContent={user.numberOfGames.toString()} color="primary">
                        <GameIcon/>
                    </Badge>
                    <span className={classes.label}>Nombre total de grilles réussies</span>
                </Box>
                <Box mb={3} className={classes.section}>
                    <MailIcon/>
                    <span className={classes.label}>{user.email}</span>
                </Box>
                <Box mb={3} className={classes.section}>
                    <CalendarIcon/>
                    <span className={classes.label}>Inscription : {user.createdAt}</span>
                </Box>
            </CardContent>
        </Card>
    );
}
