import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
    FETCH_SINGLE_USER,
    FETCH_SINGLE_USER_SUCCESS,
    FETCH_SINGLE_USER_STATS,
    FETCH_SINGLE_USER_STATS_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
    users: [],
    usersFetching: false,
    usersFetchError: '',
    singleUserDetails: {},
    singleUserDetailsFetching: true,
    stats: [],
    statsLoading: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_USERS:
            return {
                ...state,
                usersFetching: true,
                usersFetchError: ''
            };
        case FETCH_USERS_SUCCESS:
            console.log('ici success fetch')
            return {
                ...state,
                usersFetching: false,
                users: action.payload,
                usersFetchError: '',
            };
        case FETCH_USERS_FAIL:
            return {
                ...state,
                usersFetching: false,
                usersFetchError: action.payload,
            };
        case FETCH_SINGLE_USER:
            return {
                ...state,
                singleUserDetailsFetching: true,
            };
        case FETCH_SINGLE_USER_SUCCESS:
            return {
                ...state,
                singleUserDetailsFetching: false,
                singleUserDetails: action.payload,
            };
        case FETCH_SINGLE_USER_STATS:
            return {
                ...state,
                statsLoading: true,
            };
        case FETCH_SINGLE_USER_STATS_SUCCESS:
            return {
                ...state,
                statsLoading: false,
                stats: action.payload,
            };
        default:
            return {...state};
    }
}
