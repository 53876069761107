import MobileStepper from "@material-ui/core/MobileStepper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getExperienceForLevel} from "../services";

const useStyles = makeStyles((theme) => ({
    mobileRoot: {
        width: 300,
        flex: 1,
    },
    mobileProgress: {
        width: '100% !important',
        height: 10,
    },
    level: {
        fontSize: 18,
        margin: 0,
        fontWeight: 'bold',
    },
    levelSpan: {
        color: 'rgb(4, 210, 140)',
    },

}));

const ExperienceStepper = ({step, experience}) => {
    const classes = useStyles();

    const experiencePreviousLevel = getExperienceForLevel(step + 1);
    const experienceNextLevel = getExperienceForLevel(step + 2 );

    const totalExperienceForLevellingUp = experienceNextLevel - experiencePreviousLevel;

    const progression = (experience - experiencePreviousLevel) / totalExperienceForLevellingUp * 100;


    console.log({step,experiencePreviousLevel,experienceNextLevel,totalExperienceForLevellingUp,progression })

    return (
        <React.Fragment>
            <MobileStepper
                variant="progress"
                steps={100}
                position="static"
                activeStep={progression}
                classes={{root: classes.mobileRoot, progress: classes.mobileProgress}}
            />
            <p className={classes.level} style={{marginTop: 8}}>
                    <span className={classes.levelSpan}>
                        {experience - experiencePreviousLevel } xp / {totalExperienceForLevellingUp} xp
                    </span>
            </p>
        </React.Fragment>

    );
}

export default ExperienceStepper;
