import firebase from 'firebase';
import {
    DECREMENT_USER_LEVEL, DECREMENT_USER_LEVEL_FAIL, DECREMENT_USER_LEVEL_SUCCESS,
    RECORD_USER_GAME,
    RECORD_USER_GAME_FAIL,
    RECORD_USER_GAME_SUCCESS
} from './types';
import {getCode} from "../services";


export const fetchUserLevel = (uid) => {
    return new Promise((resolve, reject) => {
        firebase.database().ref(`/users/${uid}`)
            .on('value', snap  => resolve(snap.val()))
    })

};

const setNewUserLevel = (uid, newLevel) => {
    const ref = firebase.database()
        .ref(`users/${uid}`);
    return new Promise((resolve, reject) => {

        ref
            .update({level: newLevel})
            .then(() => resolve('success set new level'))
            .catch(() => reject('fail set new level'))
    });
};

const incrementNumberOfGamesPlayed = (uid) => {
    const ref = firebase.database()
        .ref(`users/${uid}`);
    return new Promise((resolve, reject) => {
        ref
            .child('numberOfGames')
            .set(firebase.database.ServerValue.increment(1))
            .then(() => resolve('success set number of games'))
            .catch(() => reject('fail set number of games'))
    });
};

const incrementExperience = (uid) => {
    const ref = firebase.database()
        .ref(`users/${uid}`);
    return new Promise((resolve, reject) => {
        ref
            .child('experience')
            .set(firebase.database.ServerValue.increment(10))
            .then(() => resolve('success set new xp'))
            .catch(() => reject('fail set new XP'))
    });
};

export const decrementUserLevel = () => {
    return (dispatch) => {
        dispatch({
            type: DECREMENT_USER_LEVEL,
        });

        const {uid} = firebase.auth().currentUser;

        fetchUserLevel(uid)
            .then((userData) => {
                const {level} = userData;
                let newLevel = level - 1;

                if (newLevel < 0) {
                    newLevel = 0;
                }
                setNewUserLevel(uid, newLevel)
                    .then(() => {
                        const newCode = getCode(newLevel);
                        dispatch({
                            type: DECREMENT_USER_LEVEL_SUCCESS, // TODO RETURN NEW CODE
                            payload: newCode,
                        });
                    })
                    .catch(() => {
                        dispatch({
                            type: DECREMENT_USER_LEVEL_FAIL,
                        });
                    })
            })
            .catch((e) => {
                dispatch({
                    type: DECREMENT_USER_LEVEL_FAIL,
                    payload: e,
                });
            })
    }
};

export const recordUserGame = (note, code) => {
    return (dispatch) => {

        dispatch({
            type: RECORD_USER_GAME,
        });

        const {currentUser} = firebase.auth();

        const ref = firebase.database()
            .ref(`userGames/${currentUser.uid}`);
        const newGameKey = ref.push().key;

        fetchUserLevel(currentUser.uid)
            .then((userData) => {
                const {level} = userData;
                let newLevel = level + 3 - note;

                if (newLevel < 0) {
                    newLevel = 0;
                } else if (newLevel > 28) {
                    newLevel = 28;
                }

                setNewUserLevel(currentUser.uid, newLevel);

                ref.child(newGameKey)
                    .update({
                        code,
                        note,
                        date: new Date(),
                    })
                    .then(() => {

                        incrementNumberOfGamesPlayed(currentUser.uid);
                        incrementExperience(currentUser.uid);

                        const newCode = getCode(newLevel);
                        dispatch({
                            type: RECORD_USER_GAME_SUCCESS,
                            payload: newCode,
                        })
                    })
                    .catch((e) => {
                        dispatch({
                            type: RECORD_USER_GAME_FAIL,
                            payload: e.message,
                        })
                    })
            })
            .catch(e => console.log('e', e));
    }
};
