import React from 'react';
import {Link} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import * as firebase from "firebase";
import {useHistory} from "react-router";
// Components

const styles = {
    sentence: {
        color: '#313954',
        fontSize: 13,
    },
    container: {
        background: '#e5e5e5',
    },
    deleteAccountBtn: {
        marginRight: 40,
    }
};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Footer() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const history = useHistory();

    const unsubscribe = () => {
        const uid = firebase.auth().currentUser.uid;
        const fetchParams = {
            method: 'POST',
            body: JSON.stringify({
                id: uid
            })
        }


        fetch('https://us-central1-adn2-66c7d.cloudfunctions.net/deleteUser', fetchParams)
            .then(r => {
                console.log(r)
                // TODO Reload current user
                history.push('/')
            })
            .catch(e => {
                console.log(e)
            })
    }

    return (
        <Box p={2} style={styles.container}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Link to="/mentions-legales" style={styles.sentence}>Mentions légales</Link>
                </Grid>
                <Grid item>
                    <Link to="/politique-confidentialite" style={styles.sentence}>Politique de confidentialité</Link>
                </Grid>
                <Grid item style={{flex: 1}}>
                    <Link to="/conditons-generales-vente" style={styles.sentence}>CGV</Link>
                </Grid>
                {
                    firebase.auth().currentUser &&
                    <Grid item style={styles.deleteAccountBtn}>
                        <Button onClick={() => handleClickOpen()}>
                            Supprimer mon compte
                        </Button>
                    </Grid>
                }

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Supprimer mon compte"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          Vous êtes sur le point de supprimer votre compte, et cette opération est irréversible.
                            Si vous êtes titulaire d'un abonnement, cela mettra fin à votre abonnement dès maintenant.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button color="secondary" variant="contained" onClick={() => unsubscribe()}>
                            Supprimer mon compte
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Box>
    );
}

export default Footer;
