import React from 'react';
// Components
import Appbar from "../components/Appbar";
import Footer from "./Footer";

const styles = {
    sentence: {
        color: 'darkgrey',
        fontSize: 13,
        marginTop: 24,
    },
    container: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    mainContent:{
        flex: 1,
        display: 'flex',
    }
};

class Layout extends React.Component {
    render() {
        return (
            <div style={styles.container}>
                <Appbar isAdmin={this.props.isAdmin}/>
                <div style={styles.mainContent}>
                    {this.props.children}
                </div>
                <Footer/>
            </div>
        );
    }

}

export default Layout;
