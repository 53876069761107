import firebase from 'firebase';
import {
    LOGIN_USER_FAIL,
    LOGIN_USER_SUCCESS,
    LOGIN_USER,
    RESET_LOGIN_ERROR,
    REGISTER_USER,
    REGISTER_USER_FAIL,
    REGISTER_USER_SUCCESS,
    RESET_REGISTER_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_FAIL,
    RESET_RESET_PASSWORD_FORM,
    REGISTER_TEST_USER,
    REGISTER_TEST_USER_SUCCESS,
    REGISTER_TEST_USER_FAIL,
} from './types';
import {push} from 'connected-react-router';
import moment from 'moment';


export const registerForTesting = (email, pass) => {
    return (dispatch) => {
        firebase.auth().languageCode = 'fr';


        dispatch({type: REGISTER_TEST_USER})
        firebase.auth().createUserWithEmailAndPassword(email, pass)
            .then((userRecord) => {
                const {user} = userRecord;
                firebase.database().ref('/users/' + user.uid)
                    .update({
                        email,
                        fullyRegistered: false,
                    })
                    .then(() => {
                        user.sendEmailVerification().then(() => {
                            dispatch({
                                type: REGISTER_TEST_USER_SUCCESS,
                                payload: user,
                            })
                        }).catch((error) => {
                            dispatch({
                                type: REGISTER_TEST_USER_FAIL,
                                payload: error.message,
                            })
                        });
                    })
                    .catch((e) => {
                        dispatch({
                            type: REGISTER_TEST_USER_FAIL,
                            payload: e.message,
                        })
                    })
            })
            .catch((error) => {
                let customErrorMessage;
                console.log(error);
                switch (error.code) {
                    case 'auth/invalid-email':
                        customErrorMessage = 'Le format email est incorrect';
                        break;
                    case 'auth/invalid-password':
                        customErrorMessage = 'Le mot de passe doit faire 6 caractères minimum';
                        break;
                    case 'auth/email-already-in-use':
                        customErrorMessage = 'Cette adresse email est déja utilisée';
                        break;
                    default:
                        customErrorMessage = 'Erreur lors de l\'inscription.';
                        break;
                }
                dispatch({
                    type: REGISTER_TEST_USER_FAIL,
                    payload: customErrorMessage,
                })
            })
    }
}

export const loginUser = (email, password) => {
    return (dispatch) => {
        dispatch({type: LOGIN_USER});
        const userEmail = email.trim();
        firebase.auth().signInWithEmailAndPassword(userEmail, password)
            .then((userRecord) => {
                const {user} = userRecord;

                firebase.database().ref('/users')
                    .child(user.uid)
                    .once('value')
                    .then((snap) => {
                        const userInfos = snap.val();
                        dispatch({
                            type: LOGIN_USER_SUCCESS,
                            payload: {
                                user,
                                userInfos,
                            },
                        });
                        dispatch(push('/app'));
                    });

            })
            .catch((e) => {
                    console.log(e);
                    let customErrorMessage;

                    switch (e.code) {
                        case 'auth/invalid-email':
                            customErrorMessage = 'Le format email est incorrect';
                            break;
                        case 'auth/wrong-password':
                            customErrorMessage = 'Le mot de passe est incorrect';
                            break;
                        case 'auth/user-not-found':
                            customErrorMessage = 'Cet utilisateur n\'existe pas';
                            break;
                        default:
                            customErrorMessage = 'Erreur lors de la connexion.';
                            break;
                    }
                    dispatch({
                        type: LOGIN_USER_FAIL,
                        payload: customErrorMessage,
                    })
                }
            );
    };
};

export const resetLoginError = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_LOGIN_ERROR,
        });
    }
};


export const registerUser = () => {
        return (dispatch) => {
            dispatch({type: REGISTER_USER})

            const currentUser = firebase.auth().currentUser;

            firebase.database().ref('/users')
                .orderByChild('email')
                .equalTo(currentUser.email)
                .once('value')
                .then((snap) => {
                        const userInfos = snap.val();
                        console.log(userInfos);
                        if (userInfos) {

                            firebase.database().ref('/users')
                                .child(currentUser.uid)
                                .update({
                                    level: 0,
                                    numberOfGames: 0,
                                    createdAt: new Date(),
                                    experience: 0,
                                    fullyRegistered: true,
                                })
                                .then(() => {
                                        dispatch({
                                            type: REGISTER_USER_SUCCESS,
                                            payload: currentUser,
                                        })
                                    dispatch(push('/app'))
                                    }
                                )
                                .catch((e) => {
                                    console.log('catch 2')
                                    dispatch({
                                        type: REGISTER_USER_FAIL,
                                        payload: e.message
                                    })
                                })
                        } else {
                            dispatch({
                                type: REGISTER_USER_FAIL,
                                payload: 'Ce compte n\'existe pas. Vous devez renseigner l\'adresse mail utilisée et vérifiée lors du test',
                            })
                        }
                    }
                )

        }
    }
;

export const resetRegisterError = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_REGISTER_ERROR,
        });
    }
};

export const resetPasswordError = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_RESET_PASSWORD_FORM,
        });
    }
};

export const resetPassword = (email) => {
    return (dispatch) => {
        dispatch({
            type: RESET_PASSWORD,
        });

        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                });
            })
            .catch((error) => {
                dispatch({
                    type: RESET_PASSWORD_FAIL,
                    payload: error
                });
            });
    }
};
